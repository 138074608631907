import EntityHelper from '@/components/cesium/entities/EntityHelper'
import JlinkUtils from '@/common/global/JlinkUtils'
import Cesium from 'cesium'

export default class GraphicsBaseHelper {
  protected uuid = JlinkUtils.random.uuid(8)
  protected entity: Cesium.Entity
  protected entityHelper: EntityHelper

  constructor (entityHelper: EntityHelper) {
    this.entity = entityHelper.entity
    this.entityHelper = entityHelper
  }

  end () {
    return this.entityHelper
  }
}
