import { ref, watch } from 'vue'

export class DeepScrollFactory<T> {
  targetRef = ref<RefreshViewContext<T>>()
  constructor (options:RefreshViewContextOptions<T>) {
    watch(() => this.targetRef.value, async function (v) {
      if (v) {
        v.setPageAndSize(options.page, options.size)
        if (options.reverse !== undefined) {
          v.setReverse(options.reverse)
        }
        if (options.autoLoad !== undefined) {
          v.setAutoLoad(options.autoLoad)
        }
        if (options.canLoadMore !== undefined) {
          v.canLoadMore(options.canLoadMore)
        }
        if (options.indexKey) {
          v.setIndexKey(options.indexKey)
        }
        if (options.dataChangeCallback) {
          v.setDataChangeListener(options.dataChangeCallback)
        }
        console.log(v)
        await v.setRemote(options.remote)
      }
    })
  }

  refresh () {
    console.log("refresh")
    this.targetRef.value?.pullRefresh()
  }

  getList () {
    return this.targetRef.value?.getList() || []
  }

  remove (fliter: Delete<T>) {
    this.targetRef.value?.deleteData(fliter)
  }

  change (change: Change<T>) {
    this.targetRef.value?.changeData(change)
  }

  append (item:T, index?:number) {
    this.targetRef.value?.appendItem(item, index)
  }
}
