import CesiumViewer from '@/components/cesium/CesiumViewer'
import Cesium, {Cartesian3, DirectionalLight, Entity, HeadingPitchRange, Matrix4} from 'cesium'
import CesiumCamera from '@/components/cesium/CesiumCamera'
import CesiumScene from '@/components/cesium/CesiumScene'
import CesiumEntity from '@/components/cesium/CesiumEntity'
import CesiumClock from '@/components/cesium/CesiumClock'
import { reactive } from 'vue'
import { ref } from 'vue/dist/vue'
import EntityHelper from '@/components/cesium/entities/EntityHelper'
import JlinkBindThis from '@/common/annotate/JlinkBindThis'
import JlinkUtils from "@/common/global/JlinkUtils";

export default class CesiumService {
  private uuid=JlinkUtils.random.uuid(8)
  viewer: CesiumViewer
  camera: CesiumCamera
  scene: CesiumScene
  entity: CesiumEntity
  clock: CesiumClock
  values = reactive({
    tileLoadProgress: 0,
    tileLevel: 0,
    cameraHeight: 0,
  })

  constructor (h: HTMLElement, options?: Cesium.Viewer.ConstructorOptions) {
    this.viewer = new CesiumViewer(h, options)
    this.camera = new CesiumCamera(this.viewer.viewer)
    this.scene = new CesiumScene(this.viewer.viewer)
    //给与一个固定方向的光源，排除时间环境光对于模型亮度的影响
    this.scene.scene.light = new DirectionalLight({
      direction: new Cartesian3(0.354925, -0.890918, -0.283358)
    })
    this.entity = new CesiumEntity(this.viewer.viewer)
    this.clock = new CesiumClock(this.viewer.viewer)
    this.scene.removePreRenderListener(this.preRenderListener)
      .addPreRenderListener(this.preRenderListener, this)
    this.scene.removeTileLoadProgressEventListener(this.tileLoadProgress)
      .addTileLoadProgressEventListener(this.tileLoadProgress, this)
    this.values.cameraHeight = this.camera?.positionCartographic()?.height || 0
    this.values.tileLevel = this.scene.getTileLevel()
    this.camera.removeOnChangedListener(this.cameraChangedListener)
      .addOnMoveEndListener(this.cameraChangedListener, this)
  }

  private preRenderListener () {
    const overlays = this.entity?.getCustomOverlayAll()
    overlays && overlays.forEach(i => {
      i.drawPosition()
    })
    const currentTileLevel = this.scene.getTileLevel()
    if (currentTileLevel !== this.values.tileLevel) {
      this.values.tileLevel = this.scene.getTileLevel()
      const models = this.entity?.getAll().filter(it => it.entity.model !== undefined)
      models && models.forEach(m => {
        m.drawModel().setLevelScale(this.values.tileLevel)
      })
    }
  }

  lookAt (e?: Entity | EntityHelper) {
    let entity: Entity | undefined
    if (e instanceof EntityHelper) {
      entity = e.entity
    } else {
      entity = e
    }
    this.viewer.setTrackedEntity(entity)
    this.viewer.setSelectedEntity(entity)
    if (this.viewer.getTrackedEntity()) {
      this.scene.removePreUpdateListener(this.lootAtUpdateListener)
        .addPreUpdateListener(this.lootAtUpdateListener)
    } else {
      this.viewer.viewer.camera.lookAtTransform(Matrix4.IDENTITY)
      this.scene.removePreUpdateListener(this.lootAtUpdateListener)
    }
  }

  @JlinkBindThis
  private async lootAtUpdateListener () {
    if (this.viewer.getTrackedEntity()) {
      const heading = new HeadingPitchRange(0, 0, 1000)
      const center = this.viewer.getTrackedEntity()?.position?.getValue(this.clock.getCurrentTime())
      if (center) {
        this.viewer.viewer.camera.lookAt(center, heading)
      }
    }
  }

  private tileLoadProgress (loopNum: number) {
    this.values.tileLoadProgress = loopNum
  }

  private cameraChangedListener () {
    this.values.cameraHeight = this.camera?.positionCartographic()?.height || 0
  }

  async openBigemap (host: string, mapId?: string, replace: boolean = true) {
    mapId && await this.viewer.setBigemapLayer(host, mapId, replace)
    // 对大气和雾启用动态照明效果
    // this.viewer.viewer.scene.globe.enableLighting = false;
    // this.viewer.viewer.scene.globe.dynamicAtmosphereLightingFromSun = false;
  }

  async openTencentMap (replace: boolean = true) {
    await this.viewer.setTencentLayer(replace)
    // 对大气和雾启用动态照明效果
    // this.viewer.viewer.scene.globe.enableLighting = false;
    // this.viewer.viewer.scene.globe.dynamicAtmosphereLightingFromSun = false;
  }

  setOptions (v: Cesium.Viewer.ConstructorOptions) {
    // const bmgl = await this.getBmgl()
    // const scene = await bmgl.getScene()
    // Object.keys(v).forEach((key) => {
    //   if (key === 'sceneMode' && v.sceneMode && v.sceneMode !== this.options.sceneMode) {
    //     scene.setMode(v.sceneMode)
    //   } else {
    //     console.warn(`options ${key} set is not defined`)
    //   }
    // })
    //
    // this.options = { ...this.options, ...v }
  }

  destroy () {
    this.camera.destroy()
    this.scene.destroy()
    this.entity.destroy()
    this.clock.destroy()
    this.viewer.destroy()
  }
}
