import {
  Component,
  createVNode,
  nextTick, reactive, ref,
  render,
  VNode
} from 'vue'
import DataException from '@/common/errors/DataException'
import Cesium from 'cesium'
import CesiumInstance from '@/components/cesium/CesiumInstance'
export default class CustomOverlayHelper {
  private readonly id: string
  private readonly zIndex: number
  private readonly viewer: Cesium.Viewer
  private readonly component: Component
  private position: JlinkLocation | undefined
  private style: FormatRecord<CSSStyleDeclaration> | undefined
  private node: VNode|undefined
  private props:StringKeyRecord<any> = reactive<StringKeyRecord<any>>({})
  private events:StringKeyRecord<any> = {}

  constructor (id: string,
    component: Component,
    zIndex: number,
    viewer: Cesium.Viewer,
    style?: FormatRecord<CSSStyleDeclaration>) {
    this.id = id
    this.zIndex = zIndex
    this.viewer = viewer
    this.style = style
    this.component = component
  }

  setProps (props:StringKeyRecord<any>) {
    Object.keys(props).forEach(i => {
      this.props[i] = props[i]
    })
    return this
  }

  setEvents (events:StringKeyRecord<Function>) {
    delete this.node
    const newEvents: StringKeyRecord<any> = {}
    if (events) {
      Object.keys(events).forEach(key => {
        let k = key as string
        if (k.startsWith('on')) {
          if (k[2] !== (k[2].toUpperCase())) {
            console.error(`events ${k} is not a useable callback name `)
          }
          newEvents[k] = events[key]
        } else {
          k = 'on' + k.charAt(0).toUpperCase() + k.slice(1)
          newEvents[k] = events[key]
        }
      })
    }
    this.events = newEvents
    return this
  }

  private updated () {
    if (!this.node) {
      this.node = createVNode(this.component, {
        id: this.id,
        style: {
          ...this.style,
        },
        ...this.props,
        ...this.events
      })
    }
    const customOverlayGroup = this.checkOverlayGroup(this.id)
    if (customOverlayGroup) {
      const groupChildren = customOverlayGroup.children
      for (let i = 0; i < groupChildren.length; i++) {
        const existElement = groupChildren.item(i)
        if (existElement?.id === this.id) {
          throw new DataException(`customOverlay ${this.id} is exist, drop!!`)
        }
      }
      const e = document.createElement('div') as HTMLElement
      e.setAttribute('id', this.id)
      e.style.pointerEvents = 'fill'
      e.style.position = 'absolute'
      e.style.zIndex = this.zIndex.toString()
      e.style.pointerEvents = 'fill'
      e.style.width = 'auto'
      e.style.height = 'auto'
      customOverlayGroup.appendChild(e)
      const _this = this
      nextTick(function () {
        render(_this.node!, e)
      })
    }
  }

  private checkOverlayGroup (id: string) {
    if (id === 'customOverlayGroup') {
      console.error(`id ${id} is group name of custom overlays ,please use a new word exclude this`)
      return undefined
    } else {
      const overlayGroup = document.getElementById('customOverlayGroup')
      if (overlayGroup) {
        return overlayGroup
      }
      console.error('customOverlay element is lost in bigemap component')
      return undefined
    }
  }

  remove () {
    const customOverlayGroup = this.checkOverlayGroup(this.id)
    if (customOverlayGroup) {
      const groupChildren = customOverlayGroup.children
      for (let i = 0; i < groupChildren.length; i++) {
        const existElement = groupChildren.item(i)
        if (existElement?.id === this.id) {
          nextTick(function () {
            customOverlayGroup.removeChild(existElement)
          })

          return
        }
      }
    }
  }

  private get () {
    const customOverlayGroup = this.checkOverlayGroup(this.id)
    if (customOverlayGroup) {
      const groupChildren = customOverlayGroup.children
      for (let i = 0; i < groupChildren.length; i++) {
        const existElement = groupChildren.item(i)
        if (existElement?.id === this.id) {
          return existElement as HTMLElement
        }
      }
    } else {
      return undefined
    }
  }

  drawPosition () {
    const _this = this

    if (this.position) {
      const c = CesiumInstance.toCartesian3FromDegrees(this.position)
      const p_2d = this.viewer.scene.cartesianToCanvasCoordinates(c)
      if (p_2d) {
        nextTick(function () {
          const e = _this.get()
          if (e) {
            const self = _this.alignSelf || true
            const offsetX = self ? e.clientWidth * (_this.offset?.x === undefined ? 0.5 : _this.offset.x) : _this.offset?.x === undefined ? 0 : -_this.offset.x
            const offsetY = self ? e.clientHeight * (_this.offset?.y === undefined ? 1 : _this.offset.y) : _this.offset?.y === undefined ? 0 : -_this.offset.y
            e.style.left = p_2d.x.toFixedCustom(1) - offsetX + 'px'
            e.style.top = p_2d.y.toFixedCustom(1) - offsetY + 'px'
            e.style.display = 'block'
          }
        })
      }
    }
  }

  private offset?: { x?: number, y?: number }
  private alignSelf?: boolean

  setPosition (position: JlinkLocation, offset?: { x?: number, y?: number }, alignSelf?: boolean) {
    this.position = position
    const e = this.get()
    if (!e) {
      const customOverlayGroup = this.checkOverlayGroup(this.id)
      if (customOverlayGroup) {
        const groupChildren = customOverlayGroup.children
        for (let i = 0; i < groupChildren.length; i++) {
          const existElement = groupChildren.item(i)
          if (existElement?.id === this.id) {
            throw new DataException(`customOverlay ${this.id} is exist, drop!!`)
          }
        }
        this.updated()
      }
    }

    this.offset = offset
    this.alignSelf = alignSelf
    this.drawPosition()
  }
}
