import GraphicsBaseHelper from '@/components/cesium/entities/GraphicsBaseHelper'
import EntityHelper from '@/components/cesium/entities/EntityHelper'
import {
  ArcType,
  CallbackProperty,
  ConstantProperty,
  Color,
  PolylineGraphics,
  Property,
  ShadowMode,
  ClassificationType,
  DistanceDisplayCondition,
  PolylineArrowMaterialProperty,
  ColorMaterialProperty,
  CompositeMaterialProperty,
  GridMaterialProperty,
  ImageMaterialProperty,
  PolylineGlowMaterialProperty,
  PolylineDashMaterialProperty,
  PolylineOutlineMaterialProperty,
  StripeMaterialProperty,
  StripeOrientation,
  Cartesian3,
  Cartesian2
} from 'cesium'
import CesiumInstance from '@/components/cesium/CesiumInstance'

export default class PolylineGraphicsHelper extends GraphicsBaseHelper {
  constructor (entityHelper: EntityHelper) {
    super(entityHelper)
    const _this = this
    if (!this.entity.polyline) {
      this.entity.polyline = new PolylineGraphics()
      this.entity.polyline.positions = new CallbackProperty(function () {
        return _this.positionSave
      }, false)
    }
  }

  private setConstantProperty<T extends keyof PolylineGraphics> (param: T, p: any) {
    if (!p) {
      return
    }
    if (this.entity.polyline![param]) {
      if (this.entity.polyline![param] instanceof ConstantProperty) {
        (this.entity.polyline![param] as ConstantProperty).setValue(p)
      } else {
        this.entity.polyline![param] = p
      }
    } else {
      this.entity.polyline![param] = p
    }
  }

  setShow (show: boolean) {
    this.setConstantProperty('show', show)
    return this
  }

  setWidth (width: number) {
    this.setConstantProperty('width', width)

    return this
  }

  setGranularity (granularity: number) {
    this.setConstantProperty('granularity', granularity)
    return this
  }

  setArcType (arcType: ArcType) {
    this.setConstantProperty('arcType', arcType)
    return this
  }

  setShadows (shadows: ShadowMode) {
    this.setConstantProperty('shadows', shadows)

    return this
  }

  setClassificationType (classificationType: ClassificationType) {
    this.setConstantProperty('classificationType', classificationType)

    return this
  }

  setDistanceDisplayCondition (near: number, far: number) {
    this.setConstantProperty('distanceDisplayCondition', new DistanceDisplayCondition(near, far))
    return this
  }

  setClampToGround (clampToGround: boolean) {
    this.setConstantProperty('clampToGround', clampToGround)
    return this
  }

  setZIndex (zIndex: number) {
    this.setConstantProperty('zIndex', zIndex)
    return this
  }

  setPolylineArrowMaterial (color: Color) {
    this.setConstantProperty('material', new PolylineArrowMaterialProperty(color))
    return this
  }

  setColorMaterial (color: Color) {
    this.setConstantProperty('material', new ColorMaterialProperty(color))
    return this
  }

  setCompositeMaterial () {
    this.setConstantProperty('material', new CompositeMaterialProperty())
    return this
  }

  setGridMaterial (options: {
    color?: Property | Color;
    cellAlpha?: Property | number;
    lineCount?: Property | Cartesian2;
    lineThickness?: Property | Cartesian2;
    lineOffset?: Property | Cartesian2;
  }) {
    this.setConstantProperty('material', new GridMaterialProperty(options))
    return this
  }

  setImageMaterial (options: {
    image?: Property | string | HTMLImageElement | HTMLCanvasElement | HTMLVideoElement;
    repeat?: Property | Cartesian2;
    color?: Property | Color;
    transparent?: Property | boolean;
  }) {
    this.setConstantProperty('material', new ImageMaterialProperty(options))
    return this
  }

  setPolylineGlowMaterial (options: {
    color?: Property | Color;
    glowPower?: Property | number;
    taperPower?: Property | number;
  }) {
    this.setConstantProperty('material', new PolylineGlowMaterialProperty(options))

    return this
  }

  setPolylineDashMaterial (options: {
    color?: Property | Color;
    gapColor?: Property | Color;
    dashLength?: Property | number;
    dashPattern?: Property | number;
  }) {
    this.setConstantProperty('material', new PolylineDashMaterialProperty(options))
    return this
  }

  setPolylineOutlineMaterial (options: {
    color?: Property | Color;
    outlineColor?: Property | Color;
    outlineWidth?: Property | number;
  }) {
    this.setConstantProperty('material', new PolylineOutlineMaterialProperty(options))

    return this
  }

  setStripeMaterial (options: {
    orientation?: Property | StripeOrientation;
    evenColor?: Property | Color;
    oddColor?: Property | Color;
    offset?: Property | number;
    repeat?: Property | number;
  }) {
    this.setConstantProperty('material', new StripeMaterialProperty(options))

    return this
  }

  setColorDepthFailMaterial (color: Color) {
    this.setConstantProperty('depthFailMaterial', new ColorMaterialProperty(color))

    return this
  }

  setCompositeDepthFailMaterial () {
    this.setConstantProperty('depthFailMaterial', new CompositeMaterialProperty())

    return this
  }

  setPolylineDashDepthFailMaterial (options: {
    color?: Property | Color;
    gapColor?: Property | Color;
    dashLength?: Property | number;
    dashPattern?: Property | number;
  }) {
    this.setConstantProperty('depthFailMaterial', new PolylineDashMaterialProperty(options))
    return this
  }

  setGridDepthFailMaterial (options: {
    color?: Property | Color;
    cellAlpha?: Property | number;
    lineCount?: Property | Cartesian2;
    lineThickness?: Property | Cartesian2;
    lineOffset?: Property | Cartesian2;
  }) {
    this.setConstantProperty('depthFailMaterial', new GridMaterialProperty(options))
    return this
  }

  setImageDepthFailMaterial (options: {
    image?: Property | string | HTMLImageElement | HTMLCanvasElement | HTMLVideoElement;
    repeat?: Property | Cartesian2;
    color?: Property | Color;
    transparent?: Property | boolean;
  }) {
    this.setConstantProperty('depthFailMaterial', new ImageMaterialProperty(options))
    return this
  }

  setPolylineGlowDepthFailMaterial (options: {
    color?: Property | Color;
    glowPower?: Property | number;
    taperPower?: Property | number;
  }) {
    this.setConstantProperty('depthFailMaterial', new PolylineGlowMaterialProperty(options))
    return this
  }

  setPolylineOutlineDepthFailMaterial (options: {
    color?: Property | Color;
    outlineColor?: Property | Color;
    outlineWidth?: Property | number;
  }) {
    this.setConstantProperty('depthFailMaterial', new PolylineOutlineMaterialProperty(options))
    return this
  }

  setStripeDepthFailMaterial (options: {
    orientation?: Property | StripeOrientation;
    evenColor?: Property | Color;
    oddColor?: Property | Color;
    offset?: Property | number;
    repeat?: Property | number;
  }) {
    this.setConstantProperty('depthFailMaterial', new StripeMaterialProperty(options))
    return this
  }

  setPolylineArrowDepthFailMaterial (color: Color) {
    this.setConstantProperty('depthFailMaterial', new PolylineArrowMaterialProperty(color))
    return this
  }

  setPositionsByArray (positions: number[]) {
    this.setPositionsByCartesian3(CesiumInstance.toCartesian3ArrayFromHeightArray(positions))
    return this
  }

  setPositionsByPositions (positions: JlinkLocation[]) {
    const p = positions.map(i => CesiumInstance.toCartesian3FromDegrees(i))
    this.setPositionsByCartesian3(p)
    return this
  }

  positionSave: Cartesian3[] = []

  setPositionsByCartesian3 (positions: Cartesian3[]) {
    const _this = this
    this.positionSave = positions

    return this
  }

  appendPositions (positions: JlinkLocation[]) {
    try {
      const p = positions.map(i => CesiumInstance.toCartesian3FromDegrees(i))
      this.positionSave.push(...p)
    } catch (e) {
      console.log(e)
    }
  }
}
