

export type ResolutionType={
  label: string,
  width: number,
  height: number,
  ratio?: string
}
const quickScan:ResolutionType[] = [{
  label: '4K(UHD)',
  width: 3840,
  height: 2160
}, {
  label: '1080p(FHD)',
  width: 1920,
  height: 1080
}, {
  label: 'UXGA',
  width: 1600,
  height: 1200,
  ratio: '4:3'
}, {
  label: '720p(HD)',
  width: 1280,
  height: 720
}, {
  label: 'SVGA',
  width: 800,
  height: 600
}, {
  label: 'VGA',
  width: 640,
  height: 480
}, {
  label: '360p(nHD)',
  width: 640,
  height: 360
}, {
  label: 'CIF',
  width: 352,
  height: 288
}, {
  label: 'QVGA',
  width: 320,
  height: 240
}, {
  label: 'QCIF',
  width: 176,
  height: 144
}, {
  label: 'QQVGA',
  width: 160,
  height: 120
}]

/**
 * @class VideoSourceInfo
 * @classDesc Source info about a video track. Values: 'camera', 'screen-cast', 'file', 'mixed'.
 * @memberOf Owt.Base
 * @readonly
 * @enum {string}
 */
export enum VideoSourceInfo {
  CAMERA= 'camera',
  SCREENCAST= 'screen-cast',
  FILE='file',
  MIXED= 'mixed'
}
export enum AudioSourceInfo {
  MIC= 'mic',
  SCREENCAST= 'screen-cast',
  FILE= 'file',
  MIXED= 'mixed'
}
export enum TrackKind {
  AUDIO= 'audio',
  VIDEO= 'video',
  AUDIO_AND_VIDEO= 'av'
}

export enum Events$1 {
  WEBRTC_NOT_SUPPORT= 'WEBRTC_NOT_SUPPORT',
  WEBRTC_ICE_CANDIDATE_ERROR= 'WEBRTC_ICE_CANDIDATE_ERROR',
  WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED= 'WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED',
  WEBRTC_ON_REMOTE_STREAMS= 'WEBRTC_ON_REMOTE_STREAMS',
  WEBRTC_ON_LOCAL_STREAM= 'WEBRTC_ON_LOCAL_STREAM',
  WEBRTC_ON_CONNECTION_STATE_CHANGE= 'WEBRTC_ON_CONNECTION_STATE_CHANGE',
  WEBRTC_ON_DATA_CHANNEL_OPEN= 'WEBRTC_ON_DATA_CHANNEL_OPEN',
  WEBRTC_ON_DATA_CHANNEL_CLOSE= 'WEBRTC_ON_DATA_CHANNEL_CLOSE',
  WEBRTC_ON_DATA_CHANNEL_ERR= 'WEBRTC_ON_DATA_CHANNEL_ERR',
  WEBRTC_ON_DATA_CHANNEL_MSG= 'WEBRTC_ON_DATA_CHANNEL_MSG',
  CAPTURE_STREAM_FAILED= 'CAPTURE_STREAM_FAILED'
}

// function GetAllScanResolution$1 () {
//   return quickScan
// }
// function GetSupportCameraResolutions$1 () {
//   return new Promise(function (resolve, reject) {
//     const resolutions:ResolutionType[] = []
//     let ok = 0
//     let err = 0
//     for (let i = 0; i < quickScan.length; ++i) {
//       const videoConstraints = new VideoTrackConstraints(VideoSourceInfo.CAMERA)
//       videoConstraints.setResolution(quickScan[i])
//       MediaStreamFactory.createMediaStream(new StreamConstraints(false, videoConstraints)).then(stream => {
//         stream.getVideoTracks().forEach(track => track.stop())
//         resolutions.push(quickScan[i])
//         ok++
//         if (ok + err === quickScan.length) {
//           resolve(resolutions)
//         }
//       }).catch(e => {
//         err++
//         if (ok + err === quickScan.length) {
//           resolve(resolutions)
//         }
//       })
//     }
//   })
// }
// function isSupportResolution$1 (w:number, h:number) {
//   return new Promise(function (resolve, reject) {
//     const videoConstraints = new VideoTrackConstraints(VideoSourceInfo.CAMERA)
//     videoConstraints.resolution = new Resolution(w, h)
//     MediaStreamFactory.createMediaStream(new StreamConstraints(false, videoConstraints)).then(stream => {
//       stream.getVideoTracks().forEach(track => track.stop())
//       resolve()
//     }).catch(e => {
//       reject(e)
//     })
//   })
// }
//
// function GetAllMediaDevice$1 () {
//   return new Promise(function (resolve, reject) {
//     if (typeof navigator.mediaDevices !== 'object' || typeof navigator.mediaDevices.enumerateDevices !== 'function') {
//       reject('enumerateDevices() not supported.')
//     } else {
//       // List cameras and microphones.
//       navigator.mediaDevices.enumerateDevices().then(devices => {
//         resolve(devices)
//       }).catch(err => {
//         reject(`${err.name}: ${err.message}`)
//       })
//     }
//   })
// }
