import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-c7894992"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  "class": "deep-image-viewer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_image_viewer = _resolveComponent("el-image-viewer");
  return $setup.imageIndex >= 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_image_viewer, {
    ref: "imageViewer",
    "initial-index": $setup.imageIndex,
    "url-list": $setup.fileUrls,
    onSwitch: $setup.switchList
  }, null, 8 /* PROPS */, ["initial-index", "url-list"])])) : _createCommentVNode("v-if", true);
}