import Cesium, { Cartesian2, JulianDate, Scene } from 'cesium'
import BaseMaker from '@/components/cesium/BaseMaker'
import CesiumInstance from '@/components/cesium/CesiumInstance'

export default class CesiumScene extends BaseMaker {
  scene: Cesium.Scene

  constructor (viewer: Cesium.Viewer) {
    super()
    this.scene = viewer.scene
  }

  setEnableRotate (enable: boolean) {
    this.scene.screenSpaceCameraController.enableRotate = enable
    return this
  }

  setEnableZoom (enable: boolean) {
    this.scene.screenSpaceCameraController.enableZoom = enable
    return this
  }

  setEnableTranslate (enable: boolean) {
    this.scene.screenSpaceCameraController.enableTranslate = enable
    return this
  }

  setEnableTilt (enable: boolean) {
    this.scene.screenSpaceCameraController.enableTilt = enable
    return this
  }

  // 获取地图层级
  getTileLevel () {
    try {
      // @ts-ignore
      const tileRender = this.scene.globe._surface._tilesToRender
      if (tileRender && tileRender.length > 0) {
        return tileRender[0]._level
      }
    } catch (e) {
      console.error(e)
      return 0
    }
    return 0
  }

  requestRender () {
    this.scene.requestRender()
  }

  //
  // setEnableInputs (enable: boolean) {
  //   this.scene.screenSpaceCameraController.enableInputs = enable
  //   return this
  // }
  //
  // setMode (mode: keyof SceneModeEnum) {
  //   this.scene.mode = this.bmglMaker.enumGet.getSceneModeEnumValue(mode)
  //   return this
  // }
  //
  globePick (ray?: Cesium.Ray) {
    return ray && this.scene.globe.pick(ray, this.scene)
  }

  //
  // globeHeight (cartographic: Cartographic) {
  //   return this.scene.globe.getHeight(cartographic)
  // }
  //
  // // 不启用地形遮挡terrainId
  // setDepthTestAgainstTerrain (depthTestAgainstTerrain: boolean) {
  //   this.scene.globe.depthTestAgainstTerrain = depthTestAgainstTerrain
  //   return this
  // }

  pick (windowPosition: Cartesian2) {
    return this.scene.pick(windowPosition)
  }

  cartesianToCanvasCoordinates (p: JlinkLocation) {
    const c = CesiumInstance.toCartesian3FromDegrees(p)
    return this.scene.cartesianToCanvasCoordinates(c)
  }

  addPostUpdateListener (callback: (scene: Scene, time: JulianDate) => void, context?: any) {
    const listener = this.getEventListenerSave('this.scene.postUpdate', this.scene.postUpdate)
    listener.register(callback, context || this)
    return this
  }

  removePostUpdateListener (callback: (scene: Scene, time: JulianDate) => void) {
    const listener = this.getEventListenerSave('this.scene.postUpdate', this.scene.postUpdate)
    listener.unRegister(callback)
    return this
  }

  addTileLoadProgressEventListener (callback: (loopNum: number) => void, context?: any) {
    const listener = this.getEventListenerSave('this.scene.globe.tileLoadProgressEvent', this.scene.globe.tileLoadProgressEvent)
    listener.register(callback, context || this)
    return this
  }

  removeTileLoadProgressEventListener (callback: (loopNum: number) => void, context?: any) {
    const listener = this.getEventListenerSave('this.scene.globe.tileLoadProgressEvent', this.scene.globe.tileLoadProgressEvent)
    listener.unRegister(callback)
    return this
  }

  addPreRenderListener (callback: (scene: Scene, time: JulianDate) => void, context?: any) {
    const listener = this.getEventListenerSave('this.scene.preRender', this.scene.preRender)
    listener.register(callback, context || this)
    return this
  }

  removePreRenderListener (callback: (scene: Scene, time: JulianDate) => void, context?: any) {
    const listener = this.getEventListenerSave('this.scene.preRender', this.scene.preRender)
    listener.unRegister(callback)
    return this
  }

  addPreUpdateListener (callback: (scene: Scene, time: JulianDate) => void, context?: any) {
    const listener = this.getEventListenerSave('this.scene.preUpdate', this.scene.preUpdate)
    listener.register(callback, context || this)
    return this
  }

  removePreUpdateListener (callback: (scene: Scene, time: JulianDate) => void) {
    const listener = this.getEventListenerSave('this.scene.preUpdate', this.scene.preUpdate)
    listener.unRegister(callback)
    return this
  }

  destroy () {
    super.destroy()
  }
}
