import { ZlEvent } from '@/components/deep-webrtc-player/zlmediakit/ZlEvent'
import { Events$1 } from '@/components/deep-webrtc-player/zlmediakit/Utils'
import axios from 'axios'
import JlinkBindThis from '@/common/annotate/JlinkBindThis'

type RTCEndpointOptions = {
  // // 是否使用聊天通道
  // usedatachannel?: boolean
  // // 是否使用聊天通道
  // audioEnable?: true
  // videoEnable?: true
  zlmsdpUrl:string
  // useCamera?:boolean
}

export class RTCEndpoint extends ZlEvent {
  private pc: RTCPeerConnection | undefined
  private _tracks: MediaStreamTrack[]
  _remoteStream: MediaStream | undefined
  _localStream: MediaStream | undefined
  private TAG: string
  private datachannel: RTCDataChannel | undefined
  // private options: RTCEndpointOptions | undefined

  constructor () {
    super('RTCPusherPlayer')
    this.TAG = '[RTCPusherPlayer]'
    this._tracks = []
    this.pc = new RTCPeerConnection()
    this.pc.onicecandidate = this._onIceCandidate
    this.pc.onicecandidateerror = this._onIceCandidateError
    this.pc.ontrack = this._onTrack
    this.pc.onconnectionstatechange = this._onconnectionstatechange
    // if (options.usedatachannel) {
    //   this.datachannel = this.pc.createDataChannel('chat')
    //   this.datachannel.onclose = this._onDataChannelClose
    //   this.datachannel.onerror = this._onDataChannelErr
    //   this.datachannel.onmessage = this._onDataChannelMsg
    //   this.datachannel.onopen = this._onDataChannelOpen
    // }
    // if ((this.options.audioEnable || this.options.videoEnable)) {
    //   this.start()
    // } else {
    //   this.receive()
    // }
  }

  play (url:string) {
    if (!url.endsWith('&type=play')) {
      url += '&type=play'
    }
    console.log(this.TAG,url)
    this.pc?.addTransceiver('video', { direction: 'recvonly' })
    this.pc?.addTransceiver('audio', { direction: 'recvonly' })
    this.pc?.createOffer().then(desc => {
      console.log(this.TAG, 'offer:', desc.sdp)
      this.pc?.setLocalDescription(desc).then(() => {
        axios({
          method: 'post',
          url,
          responseType: 'json',
          data: desc.sdp,
          headers: {
            'Content-Type': 'text/plain;charset=utf-8'
          }
        }).then(response => {
          const ret = response.data // JSON.parse(response.data);
          if (ret.code !== 0) {
            this.dispatch(Events$1.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED, ret)
            return
          }
          const anwser:RTCSessionDescriptionInit = { sdp: ret.sdp as string, type: 'answer' }
          console.log(this.TAG, 'answer:', ret.sdp)
          this.pc?.setRemoteDescription(anwser).then(() => {
            console.log(this.TAG, 'set remote sucess')
          }).catch(e => {
            console.error(this.TAG, e)
          })
        })
      })
    }).catch(e => {
      console.error(this.TAG, e)
    })
  }

  start () {
    // let videoConstraints: VideoTrackConstraints
    // let audioConstraints: AudioTrackConstraints
    // if (this.options?.useCamera) {
    //   if (this.options.videoEnable) {
    //     videoConstraints = new VideoTrackConstraints(VideoSourceInfo.CAMERA)
    //   }
    //   if (this.options.audioEnable) {
    //     audioConstraints = new AudioTrackConstraints(AudioSourceInfo.MIC)
    //   }
    // } else {
    //   if (this.options?.videoEnable) {
    //     videoConstraints = new VideoTrackConstraints(VideoSourceInfo.SCREENCAST)
    //     if (this.options.audioEnable) {
    //       audioConstraints = new AudioTrackConstraints(AudioSourceInfo.SCREENCAST)
    //     }
    //   } else {
    //     if (this.options?.audioEnable) {
    //       audioConstraints = new AudioTrackConstraints(AudioSourceInfo.MIC)
    //     } else {
    //       // error shared display media not only audio
    //       console.error(this.TAG, 'error paramter')
    //     }
    //   }
    // }
    // if (this.options.resolution.w != 0 && this.options.resolution.h != 0 && typeof videoConstraints === 'object') {
    //   videoConstraints.resolution = new Resolution(this.options.resolution.w, this.options.resolution.h)
    // }
    // if (  this.options.videoId != '') {
    //   videoConstraints.setDeviceId()
    // }
    // if (  this.options.audioId != '') {
    //   audioConstraints.setDeviceId()
    // }
    // MediaStreamFactory.createMediaStream(new StreamConstraints(audioConstraints, videoConstraints)).then(stream => {
    //   this._localStream = stream
    //   this.dispatch(Events$1.WEBRTC_ON_LOCAL_STREAM, stream)
    //   const AudioTransceiverInit = {
    //     direction: 'sendrecv',
    //     sendEncodings: []
    //   }
    //   const VideoTransceiverInit = {
    //     direction: 'sendrecv',
    //     sendEncodings: []
    //   }
    //   if (this.options.simulcast && stream.getVideoTracks().length > 0) {
    //     VideoTransceiverInit.sendEncodings = [{
    //       rid: 'h',
    //       active: true,
    //       maxBitrate: 1000000
    //     }, {
    //       rid: 'm',
    //       active: true,
    //       maxBitrate: 500000,
    //       scaleResolutionDownBy: 2
    //     }, {
    //       rid: 'l',
    //       active: true,
    //       maxBitrate: 200000,
    //       scaleResolutionDownBy: 4
    //     }]
    //   }
    //   if (this.options.audioEnable) {
    //     if (stream.getAudioTracks().length > 0) {
    //       this.pc.addTransceiver(stream.getAudioTracks()[0], AudioTransceiverInit)
    //     } else {
    //       AudioTransceiverInit.direction = 'recvonly'
    //       this.pc.addTransceiver('audio', AudioTransceiverInit)
    //     }
    //   }
    //   if (this.options.videoEnable) {
    //     if (stream.getVideoTracks().length > 0) {
    //       this.pc.addTransceiver(stream.getVideoTracks()[0], VideoTransceiverInit)
    //     } else {
    //       VideoTransceiverInit.direction = 'recvonly'
    //       this.pc.addTransceiver('video', VideoTransceiverInit)
    //     }
    //   }
    //
    //   /*
    //   stream.getTracks().forEach((track,idx)=>{
    //       debug.log(this.TAG,track);
    //       this.pc.addTrack(track);
    //   });
    //   */
    //   this.pc.createOffer().then(desc => {
    //     log(this.TAG, 'offer:', desc.sdp)
    //     this.pc.setLocalDescription(desc).then(() => {
    //       axios({
    //         method: 'post',
    //         url: this.options.zlmsdpUrl,
    //         responseType: 'json',
    //         data: desc.sdp,
    //         headers: {
    //           'Content-Type': 'text/plain;charset=utf-8'
    //         }
    //       }).then(response => {
    //         const ret = response.data // JSON.parse(response.data);
    //         if (ret.code != 0) {
    //           // mean failed for offer/anwser exchange
    //           this.dispatch(Events$1.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED, ret)
    //           return
    //         }
    //         const anwser = {}
    //         anwser.sdp = ret.sdp
    //         anwser.type = 'answer'
    //         log(this.TAG, 'answer:', ret.sdp)
    //         this.pc.setRemoteDescription(anwser).then(() => {
    //           log(this.TAG, 'set remote sucess')
    //         }).catch(e => {
    //           error(this.TAG, e)
    //         })
    //       })
    //     })
    //   }).catch(e => {
    //     error(this.TAG, e)
    //   })
    // }).catch(e => {
    //   this.dispatch(Events$1.CAPTURE_STREAM_FAILED)
    //   // debug.error(this.TAG,e);
    // })

  }

  @JlinkBindThis
  _onIceCandidate (event: RTCPeerConnectionIceEvent) {
    if (event.candidate) {
      console.log(this.TAG, 'Remote ICE candidate: \n ' + event.candidate.candidate)
    }
  }

  @JlinkBindThis
  _onTrack (event: RTCTrackEvent) {
    if (this.pc) {
      this._tracks.push(event.track)
      if (event.streams && event.streams.length > 0) {
        this._remoteStream = event.streams[0]
        this.dispatch(Events$1.WEBRTC_ON_REMOTE_STREAMS, event)
      } else {
        if (this.pc.getReceivers().length === this._tracks.length) {
          console.log(this.TAG, 'play remote stream ')
          this._remoteStream = new MediaStream(this._tracks)
        } else {
          console.error('wait stream track finish')
        }
      }
    }
  }

  @JlinkBindThis
  _onIceCandidateError (event: Event) {
    console.log(this.TAG, '_onIceCandidateError:', event)

    this.dispatch(Events$1.WEBRTC_ICE_CANDIDATE_ERROR, event)
  }

  @JlinkBindThis
  _onconnectionstatechange (event: Event) {
    console.log(this.TAG, '_onconnectionstatechange:', this.pc?.connectionState)
    this.dispatch(Events$1.WEBRTC_ON_CONNECTION_STATE_CHANGE, this.pc?.connectionState)
  }

  @JlinkBindThis
  _onDataChannelOpen (event: Event) {
    console.log(this.TAG, 'ondatachannel open:', event)
    this.dispatch(Events$1.WEBRTC_ON_DATA_CHANNEL_OPEN, event)
  }

  @JlinkBindThis
  _onDataChannelMsg (event: Event) {
    console.log(this.TAG, 'ondatachannel msg:', event)
    this.dispatch(Events$1.WEBRTC_ON_DATA_CHANNEL_MSG, event)
  }

  @JlinkBindThis
  _onDataChannelErr (event: Event) {
    console.log(this.TAG, 'ondatachannel err:', event)
    this.dispatch(Events$1.WEBRTC_ON_DATA_CHANNEL_ERR, event)
  }

  @JlinkBindThis
  _onDataChannelClose (event: Event) {
    console.log(this.TAG, 'ondatachannel close:', event)
    this.dispatch(Events$1.WEBRTC_ON_DATA_CHANNEL_CLOSE, event)
  }

  sendMsg (data: string) {
    if (this.datachannel != null) {
      this.datachannel.send(data)
    } else {
      console.error(this.TAG, 'data channel is null')
    }
  }

  // async switchVideo (deviceId?: string) {
  //   if (this.options?.videoEnable) {
  //     const currentPc = this.pc
  //     if (currentPc) {
  //       let videoConstraints: VideoTrackConstraints
  //       if (deviceId) {
  //         videoConstraints = new VideoTrackConstraints(VideoSourceInfo.CAMERA)
  //         videoConstraints.setDeviceId(deviceId)
  //       } else {
  //         videoConstraints = new VideoTrackConstraints(VideoSourceInfo.SCREENCAST)
  //       }
  //       const stream = await MediaStreamFactory.createMediaStream(new StreamConstraints(undefined, videoConstraints))
  //       const videosender = currentPc.getSenders().find(e => e.track?.kind === 'video')
  //       if (videosender && stream.getVideoTracks().length > 0) {
  //         videosender.track && this._localStream?.removeTrack(videosender.track)
  //         this._localStream?.addTrack(stream.getVideoTracks()[0])
  //         // stream change
  //         this.dispatch(Events$1.WEBRTC_ON_LOCAL_STREAM, this._localStream)
  //         return videosender.replaceTrack(stream.getVideoTracks()[0])
  //       }
  //       throw TypeError('video not exist or deviceid not vaild')
  //     } else {
  //       throw TypeError('RTCPeerConnection is not ready')
  //     }
  //   } else {
  //     throw TypeError('videoEnable is false')
  //   }
  // }
  //
  // async switchAudio (deviceId?: string) {
  //   if (this.options?.audioEnable) {
  //     const currentPc = this.pc
  //     if (currentPc) {
  //       let audioConstraints: AudioTrackConstraints
  //       if (deviceId) {
  //         audioConstraints = new AudioTrackConstraints(AudioSourceInfo.MIC)
  //         audioConstraints.setDeviceId(deviceId)
  //       } else {
  //         audioConstraints = new AudioTrackConstraints(AudioSourceInfo.SCREENCAST)
  //       }
  //       const stream = await MediaStreamFactory.createMediaStream(new StreamConstraints(audioConstraints, undefined))
  //
  //       const audiosender = currentPc.getSenders().find(e => e.track?.kind === 'audio')
  //       if (audiosender != null && stream.getAudioTracks().length > 0) {
  //         audiosender?.track && this._localStream?.removeTrack(audiosender.track)
  //         this._localStream?.addTrack(stream.getAudioTracks()[0])
  //         // stream change
  //         this.dispatch(Events$1.WEBRTC_ON_LOCAL_STREAM, this._localStream)
  //         return audiosender.replaceTrack(stream.getAudioTracks()[0])
  //       }
  //       throw TypeError('audio not exist or deviceid not vaild')
  //     } else {
  //       throw TypeError('RTCPeerConnection is not ready')
  //     }
  //   } else {
  //     throw TypeError('audioEnable is false')
  //   }
  // }

  closeDataChannel () {
    if (this.datachannel) {
      this.datachannel.close()
      delete this.datachannel
    }
  }

  close () {
    this.closeDataChannel()
    if (this.pc) {
      this.pc.close()
      delete this.pc
    }
    // if (this.options) {
    //   delete this.options
    // }
    if (this._localStream) {
      this._localStream.getTracks().forEach((track, idx) => {
        track.stop()
      })
    }
    if (this._remoteStream) {
      this._remoteStream.getTracks().forEach((track, idx) => {
        track.stop()
      })
    }
    this._tracks.forEach((track, idx) => {
      track.stop()
    })
    this._tracks = []
    this.offAll()
  }
}
