import SrsError from '@/components/deep-webrtc-player/srs/SrsError'
import SrsRtcBase from '@/components/deep-webrtc-player/srs/SrsRtcBase'
import * as stream from 'stream'

export default class SrsRtcWhipWhepAsync extends SrsRtcBase {
  private constraints = {
    audio: true,
    video: {
      width: { ideal: 320, max: 576 }
    }
  }

  constructor () {
    super()
    const _this = this
    this.pc.ontrack = function (event) {
      if (_this.ontrack) {
        _this.ontrack(event)
      }
    }
  }

  // See https://datatracker.ietf.org/doc/draft-ietf-wish-whip/
  // @url The WebRTC url to publish with, for example:
  //      http://localhost:1985/rtc/v1/whip/?app=live&stream=livestream
  async publish (url: string) {
    const _this = this
    if (url.indexOf('/whip/') === -1) throw new SrsError('URlRegexError', `invalid WHIP url ${url}`)
    this.pc.addTransceiver('audio', { direction: 'sendonly' })
    this.pc.addTransceiver('video', { direction: 'sendonly' })
    if (!navigator.mediaDevices && window.location.protocol === 'http:' && window.location.hostname !== 'localhost') {
      throw new SrsError('HttpsRequiredError', 'Please use HTTPS or localhost to publish, read https://github.com/ossrs/srs/issues/2762#issuecomment-983147576')
    }
    const stream = await navigator.mediaDevices.getUserMedia(this.constraints)
    // @see https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/addStream#Migrating_to_addTrack
    stream.getTracks().forEach(function (track) {
      _this.pc.addTrack(track)
      // Notify about local track when stream is ok.
      _this.ontrack({ track })
    })

    const offer = await this.pc.createOffer()
    console.log('Generated offer: ', offer)
    await this.pc.setLocalDescription(offer)

    const answer = await new Promise<any>(function (resolve, reject) {
      const xhr = new XMLHttpRequest()
      xhr.onload = function () {
        if (xhr.readyState !== xhr.DONE) return
        if (xhr.status !== 200 && xhr.status !== 201) return reject(xhr)
        const data = xhr.responseText as any
        console.log('Got answer: ', data)
        return data.code ? reject(xhr) : resolve(data)
      }
      xhr.open('POST', url, true)
      xhr.setRequestHeader('Content-type', 'application/sdp')
      xhr.send(offer.sdp)
    })
    await this.pc.setRemoteDescription(
      new RTCSessionDescription({ type: 'answer', sdp: answer })
    )

    return this.parseId(url, offer.sdp || '', answer)
  };

  // See https://datatracker.ietf.org/doc/draft-ietf-wish-whip/
  // @url The WebRTC url to play with, for example:
  async play (url: string) {
    let invalid = true
    if (url.includes('/whep')) {
      // 如 http://localhost:1985/rtc/v1/whep?app=live&stream=livestream
      // 符合whip/whep 标准
      invalid = false
    }
    if (url.includes('/whip-play')) {
      // 如 http://localhost:1985/rtc/v1/whip-play?app=live&stream=livestream
      // 符合whip/whep 标准
      invalid = false
    }
    if (url.includes('/webrtc')) {
      // http://localhost:1985/rtc/v1/webrtc?app=live&stream=livestream
      // 符合whip/whep 标准
      invalid = false
    }
    if (invalid) {
      throw new Error(`invalid WHEP url ${url}`)
    }
    this.pc.addTransceiver('audio', { direction: 'recvonly' })
    this.pc.addTransceiver('video', { direction: 'recvonly' })
    const offer = await this.pc.createOffer()
    await this.pc.setLocalDescription(offer)
    const answer = await new Promise<string>(function (resolve, reject) {
      console.log('Generated offer: ', offer)

      const xhr = new XMLHttpRequest()
      xhr.onload = function () {
        if (xhr.readyState !== xhr.DONE) return
        if (xhr.status !== 200 && xhr.status !== 201) return reject(xhr)
        const data = xhr.responseText as any
        console.log('Got answer: --------------------12222333', url)
        return data.code ? reject(xhr) : resolve(data)
      }
      xhr.open('POST', url, true)
      xhr.setRequestHeader('Content-type', 'application/sdp')
      xhr.send(offer.sdp)
    })
    await this.pc.setRemoteDescription(new RTCSessionDescription({ type: 'answer', sdp: answer }))
    return this.parseId(url, offer.sdp || '', answer)
  };

  private parseId (url: string, offer: string, answer: string) {
    let sessionid = offer.substr(offer.indexOf('a=ice-ufrag:') + 'a=ice-ufrag:'.length)
    sessionid = sessionid.substr(0, sessionid.indexOf('\n') - 1) + ':'
    sessionid += answer.substr(answer.indexOf('a=ice-ufrag:') + 'a=ice-ufrag:'.length)
    sessionid = sessionid.substr(0, sessionid.indexOf('\n'))

    const a = document.createElement('a')
    a.href = url
    return {
      sessionid, // Should be ice-ufrag of answer:offer.
      simulator: a.protocol + '//' + a.host + '/rtc/v1/nack/',
    }
  }
}
