import "core-js/modules/es.date.to-json.js";
import "core-js/modules/es.json.stringify.js";
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["webrtcVideoSize"];
var _hoisted_2 = {
  "class": "placeholder"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _a, _b;
  return _openBlock(), _createElementBlock("div", {
    "class": "web-rtc-view",
    webrtcVideoSize: JSON.stringify($setup.videoSize),
    ref: "webRtcViewRef"
  }, [_createElementVNode("div", _hoisted_2, [_createVNode(_Transition, {
    name: "opacity-fade-transform",
    mode: "out-in"
  }, {
    "default": _withCtx(function () {
      return [$setup.loading ? (_openBlock(), _createBlock($setup["DeepLoading"], {
        key: 0
      })) : !$setup.isPlaying && $setup.canPlay ? (_openBlock(), _createBlock($setup["DeepSvgIcon"], {
        key: 1,
        name: "play",
        "class": "icon",
        onClick: $setup.play
      })) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  })]), _createElementVNode("video", {
    ref: "videoRef",
    style: _normalizeStyle([{
      "object-fit": "cover",
      "position": "absolute"
    }, {
      width: ((_a = $setup.realVideoStyle) === null || _a === void 0 ? void 0 : _a.width) || '100%',
      height: ((_b = $setup.realVideoStyle) === null || _b === void 0 ? void 0 : _b.height) || '100%'
    }])
  }, null, 4 /* STYLE */)], 8 /* PROPS */, _hoisted_1);
}