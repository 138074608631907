import GraphicsBaseHelper from '@/components/cesium/entities/GraphicsBaseHelper'
import EntityHelper from '@/components/cesium/entities/EntityHelper'
import {
  ConstantProperty,
  LabelGraphics,
  NearFarScalar,
  Property,
  VerticalOrigin,
  Color,
  Cartesian2,
  DistanceDisplayCondition, Cartesian3, HeightReference, HorizontalOrigin, LabelStyle
} from 'cesium'

export default class LabelGraphicsHelper extends GraphicsBaseHelper {
  private text = ''
  constructor (entityHelper: EntityHelper) {
    super(entityHelper)
    const _this = this

    if (!this.entity.label) {
      this.entity.label = new LabelGraphics()
    }
  }

  private setConstantProperty<T extends keyof LabelGraphics> (param: T, p: any) {
    if (!p) {
      return
    }

    if (this.entity.label![param]) {
      if (this.entity.label![param] instanceof ConstantProperty) {
        (this.entity.label![param] as ConstantProperty).setValue(p)
      } else {
          this.entity.label![param] = p
      }
    } else {
        this.entity.label![param] = p
    }
  }

  setTranslucencyByDistance (translucencyByDistance: NearFarScalar|Property) {
    this.setConstantProperty('translucencyByDistance', translucencyByDistance)
    return this
  }

  setVerticalOrigin (verticalOrigin: VerticalOrigin|Property) {
    this.setConstantProperty('verticalOrigin', verticalOrigin)
    return this
  }

  setText (text: string|Property) {
    this.setConstantProperty('text', text)
    return this
  }

  setShow (show: boolean|Property) {
    this.setConstantProperty('show', show)
    return this
  }

  setBackgroundColor (backgroundColor: Color|Property) {
    this.setConstantProperty('backgroundColor', backgroundColor)
    return this
  }

  setFillColor (fillColor: Color|Property) {
    this.setConstantProperty('fillColor', fillColor)
    return this
  }

  setOutlineColor (outlineColor: Color|Property) {
    this.setConstantProperty('outlineColor', outlineColor)
    return this
  }

  setBackgroundPadding (x: number, y:number) {
    this.setConstantProperty('backgroundPadding', new Cartesian2(x, y))
    return this
  }

  setDistanceDisplayCondition (near: number, far: number) {
    this.setConstantProperty('distanceDisplayCondition', new DistanceDisplayCondition(near, far))
    return this
  }

  setDisableDepthTestDistance (disableDepthTestDistance:number|Property) {
    this.setConstantProperty('disableDepthTestDistance', disableDepthTestDistance)
    return this
  }

  setEyeOffset (x:number, y:number, z:number) {
    this.setConstantProperty('eyeOffset', new Cartesian3(x, y, z))
    return this
  }

  setFont (font: CSSStyleDeclaration['font']|Property) {
    this.setConstantProperty('font', font)
    return this
  }

  setHeightReference (heightReference: HeightReference) {
    this.setConstantProperty('heightReference', heightReference)
    return this
  }

  setHorizontalOrigin (horizontalOrigin: HorizontalOrigin|Property) {
    this.setConstantProperty('horizontalOrigin', horizontalOrigin)
    return this
  }

  setOutlineWidth (outlineWidth: number|Property) {
    this.setConstantProperty('outlineWidth', outlineWidth)
    return this
  }

  setPixelOffset (x: number, y:number) {
    this.setConstantProperty('pixelOffset', new Cartesian2(x, y))
    return this
  }

  setPixelOffsetScaleByDistance (pixelOffsetScaleByDistance: NearFarScalar|Property) {
    this.setConstantProperty('pixelOffsetScaleByDistance', pixelOffsetScaleByDistance)
    return this
  }

  setScale (scale: number|Property) {
    this.setConstantProperty('scale', scale)
    return this
  }

  setScaleByDistance (scaleByDistance: NearFarScalar|Property) {
    this.setConstantProperty('scaleByDistance', scaleByDistance)
    return this
  }

  setShowBackground (showBackground: boolean|Property) {
    this.setConstantProperty('showBackground', showBackground)
    return this
  }

  setStyle (style: LabelStyle) {
    this.setConstantProperty('style', style)
    return this
  }
}
