import EntityHelper from '@/components/cesium/entities/EntityHelper'
import CustomOverlayHelper from '@/components/cesium/entities/CustomOverlayHelper'
import { Component, nextTick } from 'vue'
import Cesium, { Entity, EntityCollection, HeadingPitchRange, Matrix4 } from 'cesium'
import BaseMaker from '@/components/cesium/BaseMaker'

export default class CesiumEntity extends BaseMaker {
  private readonly viewer: Cesium.Viewer

  private entities: Cesium.EntityCollection
  private customOverlays:StringKeyRecord<CustomOverlayHelper> = {}
  private customOverlaysIndex = 0

  constructor (viewer: Cesium.Viewer) {
    super()
    this.viewer = viewer
    this.entities = viewer.entities
  }

  getById (id: string) {
    const entity = this.entities.getById(id)
    // @ts-ignore
    return entity?.helper as EntityHelper | undefined
  }

  getByIds (ids: string[]) {
    return this.getAll().filter(i => {
      return ids.includes(i.entity.id)
    })
  }

  makeById (id: string) {
    const entity = new Entity({ id })
    return new EntityHelper(this, entity)
  }

  getAllValues () {
    return this.entities.values
  }

  getAll () {
    // @ts-ignore
    return this.getAllValues().mapBy('helper') as EntityHelper[]
  }

  makeCustomOverlayById (id: string, component: Component, style?: FormatRecord<CSSStyleDeclaration>) {
    let overlay = this.customOverlays[id]
    if (overlay) {
      console.error(`customOverlay ${id} have added`)
    } else {
      overlay = new CustomOverlayHelper(id, component, this.customOverlaysIndex++, this.viewer, style)
      this.customOverlays[id] = overlay
    }
    return overlay
  }

  removeCustomOverlayById (id: string) {
    const target = this.customOverlays[id]
    if (target) {
      target.remove()
      delete this.customOverlays[id]
    }
  }

  getCustomOverlayAll () {
    return Object.values(this.customOverlays) as CustomOverlayHelper[]
  }

  getCustomOverlayById (id: string) {
    return this.customOverlays[id]
  }

  removeById (id: string) {
    this.entities.removeById(id)

    // const _this = this
    // nextTick(function () {
    //   _this.bmglMaker.getScene().requestRender()
    // })
  }

  getByVagueId (id: string) {
    if (id.endsWith('*')) {
      return this.getAllIds().filter(it => it.startsWith(id.replace('*', '')))
    } else {
      console.log('暂不支持的模糊移除类型')
      return [id]
    }
  }

  removeByVagueId (id: string) {
    const _this = this
    const ids = this.getByVagueId(id)
    ids.forEach(i => {
      _this.removeById(i)
    })
  }

  removeAll () {
    this.entities.removeAll()
  }

  getAllIds () {
    return this.entities.values.mapBy('id')
  }

  add (entity: Cesium.Entity) {
    this.entities.add(entity)
    return entity
  }

  suspendEvents () {
    this.entities.suspendEvents()
    return this
  }

  resumeEvents () {
    this.entities.resumeEvents()
    return this
  }

  removeOnCollectionChangedListener (callback: (collection: EntityCollection,
                                               added: Entity[],
                                               removed: Entity[],
                                               changed: Entity[],) => void) {
    this.getEventListenerSave('this.entities.collectionChanged', this.entities.collectionChanged).unRegister(callback)
    return this
  }

  addOnCollectionChangedListener (callback: (collection: EntityCollection,
                                            added: Entity[],
                                            removed: Entity[],
                                            changed: Entity[],) => void, context?: any) {
    this.getEventListenerSave('this.entities.collectionChanged', this.entities.collectionChanged).register(callback, context)
    return this
  }

  destroy () {
    super.destroy()
    this.entities.removeAll()
    Object.values(this.customOverlays).forEach(e => {
      e.remove()
    })
    this.customOverlays = {}
  }
}
