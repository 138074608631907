import GraphicsBaseHelper from '@/components/cesium/entities/GraphicsBaseHelper'
import EntityHelper from '@/components/cesium/entities/EntityHelper'
import {
  ConstantProperty,
  HeightReference,
  Color,
  PointGraphics,
  Property,
  NearFarScalar,
  DistanceDisplayCondition
} from 'cesium'

export default class PointGraphicsHelper extends GraphicsBaseHelper {
  constructor (entityHelper: EntityHelper) {
    super(entityHelper)
    if (!this.entity.point) {
      this.entity.point = new PointGraphics()
    }
  }

  private setConstantProperty<T extends keyof PointGraphics> (param: T, p: any) {
    if (!p) {
      return
    }

    if (this.entity.point![param]) {
      if (this.entity.point![param] instanceof ConstantProperty) {
        (this.entity.point![param] as ConstantProperty).setValue(p)
      } else {
          this.entity.point![param] = p
      }
    } else {
        this.entity.point![param] = p
    }
  }

  setPixelSize (pixelSize: number) {
    this.setConstantProperty('pixelSize', pixelSize)
    return this
  }

  setHeightReference (heightReference: HeightReference) {
    this.setConstantProperty('heightReference', heightReference)
    return this
  }

  setColor (color: Color) {
    this.setConstantProperty('color', color)
    return this
  }

  setOutlineColor (outlineColor: Color) {
    this.setConstantProperty('outlineColor', outlineColor)
    return this
  }

  setOutlineWidth (outlineWidth: number) {
    this.setConstantProperty('outlineWidth', outlineWidth)
    return this
  }

  setScaleByDistance (scaleByDistance: NearFarScalar) {
    this.setConstantProperty('scaleByDistance', scaleByDistance)
    return this
  }

  setTranslucencyByDistance (translucencyByDistance: NearFarScalar) {
    this.setConstantProperty('translucencyByDistance', translucencyByDistance)
    return this
  }

  setDisableDepthTestDistance (near: number) {
    this.setConstantProperty('disableDepthTestDistance', near)
    return this
  }

  setShow (show: boolean) {
    this.setConstantProperty('show', show)
    return this
  }

  setDistanceDisplayCondition (near: number, far: number) {
    this.setConstantProperty('distanceDisplayCondition', new DistanceDisplayCondition(near, far))
    return this
  }
}
