import Cesium, {
  Cartesian3,
  Matrix4,
  Math,
  Ellipsoid,
  HeadingPitchRange,
  Camera,
  EasingFunction,
  Cartesian2
} from 'cesium'
import BaseMaker from '@/components/cesium/BaseMaker'
import CesiumInstance from '@/components/cesium/CesiumInstance'

export default class CesiumCamera extends BaseMaker {
  camera: Cesium.Camera

  constructor (viewer: Cesium.Viewer) {
    super()
    this.camera = viewer.scene.camera
  }

  setView (location: JlinkLocation, heading?: number, pitch?: number, roll?: number) {
    this.camera.setView({
      destination: Cartesian3.fromDegrees(location.lng, location.lat, location.height),
      orientation: {
        heading: Math.toRadians(heading || 0),
        pitch: Math.toRadians(pitch || 0),
        roll: Math.toRadians(roll || 0)
      },
      endTransform: Matrix4.IDENTITY
    }
    )
  }

  //
  // getViewRectangle () {
  //   const scene = this.bmglMaker.getScene()
  //   if (scene.getMode() === 'SCENE3D') {
  //     return this.camera.computeViewRectangle()
  //   } else {
  //     const canvas = scene.scene.canvas
  //     const upperLeft = this.bmglMaker.instanceCartesian2(0, 0)// canvas左上角坐标转2d坐标
  //     const lowerRight = this.bmglMaker.instanceCartesian2(
  //       canvas.clientWidth,
  //       canvas.clientHeight
  //     )// canvas右下角坐标转2d坐标
  //
  //     const ellipsoid = scene.scene.globe.ellipsoid
  //     const upperLeft3 = this.camera.pickEllipsoid(
  //       upperLeft,
  //       ellipsoid
  //     )// 2D转3D世界坐标
  //
  //     const lowerRight3 = this.camera.pickEllipsoid(
  //       lowerRight,
  //       ellipsoid
  //     )// 2D转3D世界坐标
  //
  //     const upperLeftCartographic = scene.scene.globe.ellipsoid.cartesianToCartographic(upperLeft3)// 3D世界坐标转弧度
  //     const lowerRightCartographic = scene.scene.globe.ellipsoid.cartesianToCartographic(lowerRight3)// 3D世界坐标转弧度
  //     return this.bmglMaker.instanceRectangle(upperLeftCartographic.longitude, lowerRightCartographic.latitude, lowerRightCartographic.longitude, upperLeftCartographic.latitude)
  //   }
  //   // north west 西北
  //   // constant nw:BigemapLocation={lat:this.bmglMaker.valueGet.toDegreesFromRadians(rectangle.north),lng:this.bmglMaker.valueGet.toDegreesFromRadians(rectangle?.west),height:this.bmglMaker.valueGet.toDegreesFromRadians(this.bmglMaker.valueGet.getRectangleHeight(rectangle))}
  //   // console.log("西北经度：" +JSON.stringify(nw));
  //   // south east 东南
  //   // constant se:BigemapLocation={lat:this.bmglMaker.valueGet.toDegreesFromRadians(rectangle.south),lng:this.bmglMaker.valueGet.toDegreesFromRadians(rectangle?.east),height:this.bmglMaker.valueGet.toDegreesFromRadians(this.bmglMaker.valueGet.getRectangleHeight(rectangle))}
  //   // console.log("东南经度：" +JSON.stringify(se));
  // }
  //
  // getPosition () {
  //   const p = this.getCameraCartographic()
  //   return {
  //     lng: this.bmglMaker.valueGet.toDegreesFromRadians(p.longitude),
  //     lat: this.bmglMaker.valueGet.toDegreesFromRadians(p.latitude),
  //     height: p.height,
  //     heading: this.bmglMaker.valueGet.toDegreesFromRadians(this.camera.heading),
  //     roll: this.bmglMaker.valueGet.toDegreesFromRadians(this.camera.roll),
  //     pitch: this.bmglMaker.valueGet.toDegreesFromRadians(this.camera.pitch)
  //   }
  // }
  //
  flyTo (position: JlinkLocation, options?: {
    duration?: number
    maximumHeight?: number
    heading?: number
    pitch?: number
    roll?: number
    range?: number
  }) {
    if (options?.range) {
      position.height += options?.range
    }
    return new Promise<void>((resolve, reject) => {
      this.camera.flyTo({
        destination: CesiumInstance.toCartesian3FromDegrees(position),
        orientation: {
          heading: options?.heading,
          pitch: options?.pitch,
          roll: options?.roll,
        },
        maximumHeight: options?.maximumHeight,
        duration: options?.duration || 1,
        complete: function () {
          resolve()
        },
        cancel: function () {
          reject(new Error('cancel'))
        }
      })
    })
  }

  flyToAll (position: JlinkLocation[], options?: {
    duration?: number
    maximumHeight?: number
    heading?: number
    pitch?: number
    roll?: number
    range?: number
  }) {
    return new Promise<void>((resolve, reject) => {
      console.log('fly to')
      if (position.length === 0) {
        resolve()
        return
      }
      if (position.length === 1) {
        this.flyTo(position[0], options)
          .then(() => {
            resolve()
          }).catch(e => {
            reject(e)
          })
        return
      }
      console.log(position)
      const cartesian3s = position.map(it => CesiumInstance.toCartesian3FromDegrees(it))
      let rect = CesiumInstance.toRectangleFromCartesianArray(cartesian3s)

      console.log(rect)
      if (options?.range) {
        const positionSubsample = CesiumInstance.toCartesian3ArrayFromRectangleSubSample(rect, undefined, 10000)
        rect = CesiumInstance.toRectangleFromCartesianArray(positionSubsample)
        // rect.height+=options.range
      }
      this.camera.flyTo({
        destination: rect,
        orientation: {
          heading: options?.heading,
          pitch: options?.pitch,
          roll: options?.roll,
        },
        maximumHeight: options?.maximumHeight,
        duration: options?.duration || 1,
        complete: function () {
          resolve()
        },
        cancel: function () {
          reject(new Error('cancel'))
        }
      })
    })
  }

  positionCartographic () {
    return this.camera.positionCartographic
  }

  //
  getPickRay (windowPosition: Cartesian2, result?: Cesium.Ray) {
    return this.camera.getPickRay(windowPosition, result)
  }

  pickEllipsoid (windowPosition: Cartesian2, ellipsoid?: Ellipsoid, result?: Cartesian3) {
    return this.camera.pickEllipsoid(windowPosition, ellipsoid, result)
  }

  lookAt (data: { target: Cartesian3, offset: Cartesian3 | HeadingPitchRange }) {
    return this.camera.lookAt(data.target, data.offset)
  }

  setZoomIn (amount: number) {
    this.camera.zoomIn(amount)
    return this
  }

  setZoomOut (amount: number) {
    this.camera.zoomOut(amount)
    return this
  }

  addOnChangedListener (callback: (percentage: number) => void, context?: any) {
    this.getEventListenerSave('this.camera.changed', this.camera.changed).register(callback, context)
    return this
  }

  removeOnChangedListener (callback: (percentage: number) => void) {
    this.getEventListenerSave('this.camera.changed', this.camera.changed).unRegister(callback)
    return this
  }

  addOnMoveStartListener (callback: () => void, context?: any) {
    this.getEventListenerSave('this.camera.moveStart', this.camera.moveStart).register(callback, context)
    return this
  }

  removeOnMoveStartListener (callback: () => void) {
    this.getEventListenerSave('this.camera.moveStart', this.camera.moveStart).unRegister(callback)
    return this
  }

  addOnMoveEndListener (callback: () => void, context?: any) {
    this.getEventListenerSave('this.camera.moveEnd', this.camera.moveEnd).register(callback, context)
    return this
  }

  removeOnMoveEndListener (callback: () => void) {
    this.getEventListenerSave('this.camera.moveEnd', this.camera.moveEnd).unRegister(callback)
    return this
  }

  rotate (axis: Cartesian3, angle: number) {
    this.camera.rotate(axis, angle)
  }

  flyHome (duration?: number) {
    this.camera.flyHome(duration || 0)
  }

  flyToBoundingSphere (boundingSphere: Cesium.BoundingSphere, options?: {
    duration?: number;
    offset?: HeadingPitchRange;
    complete?: Camera.FlightCompleteCallback;
    cancel?: Camera.FlightCancelledCallback;
    endTransform?: Matrix4;
    maximumHeight?: number;
    pitchAdjustHeight?: number;
    flyOverLongitude?: number;
    flyOverLongitudeWeight?: number;
    easingFunction?: EasingFunction.Callback;
  }) {
    this.camera.flyToBoundingSphere(boundingSphere, options)
  }

  moveLeft (amount: number) {
    this.camera.moveLeft(amount)
  }

  moveRight (amount: number) {
    this.camera.moveRight(amount)
  }

  getMagnitude () {
    return this.camera.getMagnitude()
  }

  destroy () {
    super.destroy()
  }
}
