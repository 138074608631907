import Timeout = NodeJS.Timeout;
import * as Cesium from 'cesium'
import {
  BoundingSphere,
  Camera,
  HeadingPitchRange,
  SceneMode,
  Math,
  Entity,
  Cartesian2,
  ScreenSpaceEventType, JulianDate, Cartesian3, ImageryProvider
} from 'cesium'
import axios from 'axios'
import BaseMaker from '@/components/cesium/BaseMaker'
import CesiumInstance from '@/components/cesium/CesiumInstance'
import EntityHelper from '@/components/cesium/entities/EntityHelper'
import {
  ScreenSpaceEventTypeHandlerE,
  ScreenSpaceEventTypeHandlerECustom
} from '@/components/cesium/ScreenSpaceEventHandler'

export default class CesiumViewer extends BaseMaker {
  viewer: Cesium.Viewer

  // private mapSaveKey: string = ''
  private autoMakeEventSaveMaker: {
    make: <T extends keyof ScreenSpaceEventTypeHandlerECustom>(event: T) => Set<ScreenSpaceEventTypeHandlerECustom[T]>,
    destroy: () => void
  }

  private autoMakeEventSave () {
    let eventSave: FormatRecordAsSet<ScreenSpaceEventTypeHandlerECustom> = {}
    let eventSaveParent: FormatRecord<ScreenSpaceEventTypeHandlerE> = {}
    let timeOut: Timeout | undefined
    let dragStart: boolean = false
    const modifier = undefined
    const _this = this

    function leftDown (e: { position: Cartesian2 }) {
      if (eventSave.LEFT_DOWN) {
        eventSave.LEFT_DOWN.forEach(i => {
          i(e)
        })
      }
      if (eventSave.DRAG_START) {
        dragStart = true
        eventSave.DRAG_START.forEach(i => {
          i(e.position)
        })
      }
      timeOut && clearTimeout(timeOut)
      if (eventSave.LONG_PRESS) {
        timeOut = setTimeout(function () {
          eventSave.LONG_PRESS!.forEach(i => {
            i(e.position)
          })
        }, 500)
      }
    }

    function leftUp (e: { position: Cartesian2 }) {
      if (eventSave.LEFT_UP) {
        eventSave.LEFT_UP.forEach(i => {
          i(e)
        })
      }
      dragStart = false
      if (eventSave.DRAG_END) {
        eventSave.DRAG_END.forEach(i => {
          i(e.position)
        })
      }
      if (eventSave.LONG_PRESS) {
        timeOut && clearTimeout(timeOut)
      }
    }

    function mouseMove (e: { startPosition: Cartesian2, endPosition: Cartesian2 }) {
      if (eventSave.MOUSE_MOVE) {
        eventSave.MOUSE_MOVE.forEach(i => {
          i(e)
        })
      }
      if (eventSave.DRAG) {
        if (dragStart) {
          eventSave.DRAG.forEach(i => {
            i(e.endPosition)
          })
        }
      }
    }

    function make<T extends keyof ScreenSpaceEventTypeHandlerECustom> (event: T): Set<ScreenSpaceEventTypeHandlerECustom[T]> {
      console.log('make', event)
      if (!eventSave[event]) {
        eventSave[event] = new Set<any>()
        if (event === 'LONG_PRESS' || event === 'LEFT_DOWN' || event === 'DRAG_START') {
          if (!eventSaveParent.LEFT_DOWN) {
            eventSaveParent.LEFT_DOWN = leftDown
            _this.viewer.screenSpaceEventHandler.setInputAction(eventSaveParent.LEFT_DOWN!, ScreenSpaceEventType.LEFT_DOWN, modifier)
          }
        }
        if (event === 'LEFT_UP' || event === 'DRAG_END' || event === 'DRAG_START') {
          if (!eventSaveParent.LEFT_UP) {
            eventSaveParent.LEFT_UP = leftUp
            _this.viewer.screenSpaceEventHandler.setInputAction(eventSaveParent.LEFT_UP!!, ScreenSpaceEventType.LEFT_UP, modifier)
          }
        }

        if (event === 'DRAG') {
          if (!eventSaveParent.MOUSE_MOVE) {
            eventSaveParent.MOUSE_MOVE = mouseMove
            _this.viewer.screenSpaceEventHandler.setInputAction(eventSaveParent.MOUSE_MOVE!!, ScreenSpaceEventType.MOUSE_MOVE, modifier)
          }
        }

        const otherTypes = event
        // @ts-ignore
        if (!eventSaveParent[otherTypes] && ScreenSpaceEventType[otherTypes]) {
          // @ts-ignore
          eventSaveParent[otherTypes] = function () {
            const args = arguments
            // @ts-ignore
            eventSave[otherTypes].forEach(i => {
              // @ts-ignore
              i(...args)
            })
          }
          // @ts-ignore
          _this.viewer.screenSpaceEventHandler.setInputAction(eventSaveParent[otherTypes], ScreenSpaceEventType[otherTypes], modifier)
        }
        return eventSave[event]!
      }
      return eventSave[event]!
    }

    function destroy () {
      eventSave = {}
      Object.keys(eventSaveParent).forEach(i => {
        _this.viewer.screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType[i], modifier)
      })
      eventSaveParent = {}
    }

    return { make, destroy }
  }

  constructor (h: HTMLElement, options?: Cesium.Viewer.ConstructorOptions) {
    super()
    this.viewer = new Cesium.Viewer(h, { ...options })
    // @ts-ignore
    this.viewer.cesiumWidget.creditContainer.style.display = 'none'
    this.autoMakeEventSaveMaker = this.autoMakeEventSave()
  }

  async setTencentLayer (replace: boolean) {
    if (replace) {
      this.viewer.imageryLayers.removeAll()
    }
    this.add(function () {
      return new Cesium.ImageryLayer(new Cesium.UrlTemplateImageryProvider({
        maximumLevel: 17,
        url: 'https://p2.map.gtimg.com/sateTiles/{z}/{sx}/{sy}/{x}_{reverseY}.jpg?version=244',
        customTags: {
          sx: function (imageryProvider: ImageryProvider, x: number, y: number, level: number) {
            return x >> 4
          },
          sy: function (imageryProvider: ImageryProvider, x: number, y: number, level: number) {
            return ((1 << level) - y) >> 4
          }
        }

      }), {})
    }, this.viewer.imageryLayers.length)
    this.add(function () {
      return new Cesium.ImageryLayer(new Cesium.UrlTemplateImageryProvider({
        maximumLevel: 17,
        url: 'https://rt3.map.gtimg.com/tile?z={z}&x={x}&y={reverseY}&styleid=2&version=859'
      }), {})
    }, this.viewer.imageryLayers.length)
  }

  async setBigemapLayer (host: string, mapId: String, replace: boolean) {
    if (replace) {
      this.viewer.imageryLayers.removeAll()
    }
    const tokenResponse = await axios.get(`${host}/tokens/v1`)
    const token = tokenResponse.data.token
    const mapConfigResponse = await axios.get(`${host}/v3/${mapId}.json?access_token=${token}`)
    const mapConfig = mapConfigResponse.data
    const tiles: string[] = mapConfig.tiles
    const type: 'IMAGERY' | 'TERRAIN' = mapConfig.type
    const links: { terrainLink: string } = mapConfig.links
    if (type !== 'IMAGERY') {
      throw new Error(`${mapId} from bigemap  is not a IMAGERY source`)
    }
    for (const t of tiles) {
      this.add(function () {
        return new Cesium.ImageryLayer(new Cesium.UrlTemplateImageryProvider({ url: t }), {})
      }, this.viewer.imageryLayers.length)
    }

    const linkKeys = Object.keys(links)
    for (const linkKey of linkKeys) {
      if (linkKey === 'terrainLink') {
        // const terrainConfigResponse = await axios.get(`${host}/v3/${linkKey}.json?access_token=${token}`)
        // const terrainConfig = terrainConfigResponse.data
        // const type: 'IMAGERY' | 'TERRAIN' = terrainConfig.type
        // const tiles: string[] = terrainConfig.tiles
        // if (type !== "IMAGERY") {
        //   throw new Error(`${linkKey} from bigemap is not a TERRAIN source`)
        // }
        // for (const t of tiles) {
        //   // @ts-ignore
        //   // this.viewer.terrainProvider=new Cesium.CesiumTerrainProvider({
        //   //
        //   //   url : 'https://elevation3d.arcgis.com/arcgis/rest/services/WorldElevation3D/Terrain3D/ImageServer',
        //   //   token : 'KED1aF_I4UzXOHy3BnhwyBHU4l5oY6rO6walkmHoYqGp4XyIWUd5YZUC1ZrLAzvV40pR6gBXQayh0eFA8m6vPg..'
        //   // });
        //   // this.setImageryLayer(function () {
        //   //   return new Cesium.ImageryLayer(new Cesium.UrlTemplateImageryProvider({url: t}), {})
        //   // }, this.viewer.imageryLayers.length)
        // }
      } else {
        console.warn(`linkKey ${linkKey} is not support`)
      }
    }
  }

  removeAll (destroy: boolean = true) {
    this.viewer.imageryLayers.removeAll(destroy)
    return this
  }

  // 设置地球的地图
  add (newInstance: () => Cesium.ImageryLayer, index: number) {
    const layer = this.viewer.imageryLayers.get(index)
    if (layer !== undefined && !layer.isDestroyed()) {
      this.viewer.imageryLayers.remove(layer, true)
    }
    const newLayer = newInstance()
    this.viewer.imageryLayers.add(newLayer, index)
    return this
  }

  setEvents<T extends keyof ScreenSpaceEventTypeHandlerECustom> (type: T, callback: ScreenSpaceEventTypeHandlerECustom[T]) {
    this.autoMakeEventSaveMaker.make(type).add(callback)
    return this
  }

  setTrackedEntity (entity?: Cesium.Entity) {
    if (this.viewer.trackedEntity?.id !== entity?.id) {
      this.viewer.trackedEntity = entity
    }
    return this
  }

  getTrackedEntity () {
    return this.viewer.trackedEntity
  }

  setSelectedEntity (entity?: Cesium.Entity | EntityHelper) {
    let target: Cesium.Entity|undefined
    if (entity instanceof EntityHelper) {
      target = entity.entity
    } else {
      target = entity
    }
    if (this.viewer.selectedEntity?.id !== target?.id) {
      this.viewer.selectedEntity = target
    }

    return this
  }

  setTerrainShadows (mode: Cesium.ShadowMode) {
    this.viewer.terrainShadows = mode
    return this
  }

  async flyTo (target: Cesium.Entity[], o?: { duration?: number, maximumHeight?: number, offset?: HeadingPitchRange }) {
    const options = { duration: 1, offset: new HeadingPitchRange(0, 0, 5000), ...o }
    if (options?.offset) {
      const points: Cartesian3[] = []
      for (const e of target) {
        const t = e.position?.getValue(JulianDate.now())
        if (t) {
          points.push(t)
        }
      }
      const headingPitchRange = await this.adjustBoundingSphereOffset(points)
      if (headingPitchRange) {
        options.offset.range += headingPitchRange.range
        // options.offset.pitch += headingPitchRange.pitch
        // options.offset.heading += headingPitchRange.heading
      }
    }
    return await this.viewer.flyTo(target, options)
  }

  async flyToById (ids: string[], options?: { duration?: number, maximumHeight?: number, offset?: HeadingPitchRange }) {
    const target = this.viewer.entities.values.filterIf('id', ids)
    return await this.flyTo(target, { duration: 1, offset: new HeadingPitchRange(0, 0, 5000), ...options })
  }

  async zoomTo (target: Cesium.Entity[], offset?: HeadingPitchRange) {
    if (offset) {
      const points: Cartesian3[] = []
      for (const e of target) {
        const t = e.position?.getValue(JulianDate.now())
        if (t) {
          points.push(t)
        }
      }

      const headingPitchRange = await this.adjustBoundingSphereOffset(points)
      if (headingPitchRange) {
        offset.range += headingPitchRange.range
        // options.offset.pitch += headingPitchRange.pitch
        // options.offset.heading += headingPitchRange.heading
      }
    }
    return this.viewer.zoomTo(target, offset)
  }

  async flyToEntities (options?: { duration?: number, maximumHeight?: number, offset?: HeadingPitchRange }) {
    if (options?.offset) {
      const points: Cartesian3[] = []
      for (const e of this.viewer.entities.values) {
        const t = e.position?.getValue(JulianDate.now())
        if (t) {
          points.push(t)
        }
      }

      const headingPitchRange = await this.adjustBoundingSphereOffset(points)
      if (headingPitchRange) {
        options.offset.range += headingPitchRange.range
        // options.offset.pitch += headingPitchRange.pitch
        // options.offset.heading += headingPitchRange.heading
      }
    }
    return await this.viewer.flyTo(this.viewer.entities, {
      duration: 1,
      offset: new HeadingPitchRange(0, 0, 5000),
      ...options
    })
  }

  private async adjustBoundingSphereOffset (points: Cartesian3[]) {
    const boundingSpheres = BoundingSphere.fromPoints(points, new BoundingSphere())
    if (boundingSpheres) {
      const scene = this.viewer.scene
      const camera = this.viewer.camera
      const headingPitchRange = HeadingPitchRange.clone(Camera.DEFAULT_OFFSET)
      const minimumZoom = scene.screenSpaceCameraController.minimumZoomDistance
      const maximumZoom = scene.screenSpaceCameraController.maximumZoomDistance
      const range = headingPitchRange.range
      if (range >= 0.0) {
        const radius = boundingSpheres.radius
        if (radius === 0.0) {
          headingPitchRange.range = 100
        } else if (scene.mode === SceneMode.SCENE2D) {
          headingPitchRange.range = CesiumInstance.distanceToBoundingSphere2D(camera.frustum, radius)
        } else {
          headingPitchRange.range = CesiumInstance.distanceToBoundingSphere3D(camera.frustum, radius)
        }
        headingPitchRange.range = Math.clamp(headingPitchRange.range, minimumZoom, maximumZoom)
      }
      return headingPitchRange
    } else {
      return undefined
    }
  }

  async zoomToEntities (offset?: HeadingPitchRange) {
    if (offset) {
      const points: Cartesian3[] = []
      for (const e of this.viewer.entities.values) {
        const t = e.position?.getValue(JulianDate.now())
        if (t) {
          points.push(t)
        }
      }
      const headingPitchRange = await this.adjustBoundingSphereOffset(points)
      if (headingPitchRange) {
        offset.range += headingPitchRange.range
        // options.offset.pitch += headingPitchRange.pitch
        // options.offset.heading += headingPitchRange.heading
      }
    }
    return await this.zoomTo(this.viewer.entities.values, offset)
  }

  addOnTrackedEntityChanged (callback: (e: Entity) => void, context?: any) {
    this.getEventListenerSave('this.viewer.trackedEntityChanged', this.viewer.trackedEntityChanged).register(callback, context)
    return this
  }

  removeOnTrackedEntityChanged (callback: (e?: Entity) => void) {
    this.getEventListenerSave('this.viewer.trackedEntityChanged', this.viewer.trackedEntityChanged).unRegister(callback)
    return this
  }

  addOnSelectedEntityChanged (callback: (e: Entity) => void, context?: any) {
    this.getEventListenerSave('this.viewer.selectedEntityChanged', this.viewer.selectedEntityChanged).register(callback, context)
    return this
  }

  removeOnSelectedEntityChanged (callback: (e?: Entity) => void) {
    this.getEventListenerSave('this.viewer.selectedEntityChanged', this.viewer.selectedEntityChanged).unRegister(callback)
    return this
  }

  destroy () {
    super.destroy()
    this.autoMakeEventSaveMaker.destroy()
    this.viewer?.destroy()
  }
}
