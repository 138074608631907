import GraphicsBaseHelper from '@/components/cesium/entities/GraphicsBaseHelper'
import EntityHelper from '@/components/cesium/entities/EntityHelper'
import {
  ConstantProperty,
  CylinderGraphics,
  HeightReference,
  Color,
  Property,
  ShadowMode,
  DistanceDisplayCondition,
  PolylineArrowMaterialProperty,
  ColorMaterialProperty,
  CompositeMaterialProperty,
  GridMaterialProperty,
  Cartesian2,
  ImageMaterialProperty,
  PolylineGlowMaterialProperty,
  PolylineDashMaterialProperty,
  PolylineOutlineMaterialProperty, StripeMaterialProperty, StripeOrientation
} from 'cesium'

export default class CylinderGraphicsHelper extends GraphicsBaseHelper {
  constructor (entityHelper: EntityHelper) {
    super(entityHelper)
    if (!this.entity.polygon) {
      this.entity.cylinder = new CylinderGraphics()
    }
  }

  private setConstantProperty<T extends keyof CylinderGraphics> (param: T, p: any) {
    if (!p) {
      return
    }

    if (this.entity.cylinder![param]) {
      if (this.entity.cylinder![param] instanceof ConstantProperty) {
        (this.entity.cylinder![param] as ConstantProperty).setValue(p)
      } else {
          // @ts-ignore
          this.entity.cylinder![param] = p
      }
    } else {
        // @ts-ignore
        this.entity.cylinder![param] = p
    }
  }

  setHeightReference (heightReference: HeightReference|Property) {
    this.setConstantProperty('heightReference', heightReference)
    return this
  }

  setBottomRadius (bottomRadius: number|Property) {
    this.setConstantProperty('bottomRadius', bottomRadius)
    return this
  }

  setTopRadius (topRadius: number|Property) {
    this.setConstantProperty('topRadius', topRadius)
    return this
  }

  setLength (length: number|Property) {
    this.setConstantProperty('length', length)
    return this
  }

  setFill (fill: boolean|Property) {
    this.setConstantProperty('fill', fill)
    return this
  }

  setOutline (outline: boolean|Property) {
    this.setConstantProperty('outline', outline)
    return this
  }

  setOutlineColor (outlineColor: Color|Property) {
    this.setConstantProperty('outlineColor', outlineColor)
    return this
  }

  setOutlineWidth (outlineWidth: number|Property) {
    this.setConstantProperty('outlineWidth', outlineWidth)
    return this
  }

  setSlices (slices: number|Property) {
    this.setConstantProperty('slices', slices)
    return this
  }

  setNumberOfVerticalLines (numberOfVerticalLines: number|Property) {
    this.setConstantProperty('numberOfVerticalLines', numberOfVerticalLines)
    return this
  }

  setShow (show: boolean|Property) {
    this.setConstantProperty('show', show)
    return this
  }

  setShadows (shadows: ShadowMode|Property) {
    this.setConstantProperty('shadows', shadows)
    return this
  }

  setDistanceDisplayCondition (near: number, far: number) {
    this.setConstantProperty('distanceDisplayCondition', new DistanceDisplayCondition(near, far))
    return this
  }
  /* material设置方法除了colorMaterial其他待验证 */

  setPolylineArrowMaterial (color: Color) {
    this.setConstantProperty('material', new PolylineArrowMaterialProperty(color))
    return this
  }

  setColorMaterial (color: Color) {
    this.setConstantProperty('material', new ColorMaterialProperty(color))
    return this
  }

  setCompositeMaterial () {
    this.setConstantProperty('material', new CompositeMaterialProperty())
    return this
  }

  setGridMaterial (options: {
    color?: Property | Color;
    cellAlpha?: Property | number;
    lineCount?: Property | Cartesian2;
    lineThickness?: Property | Cartesian2;
    lineOffset?: Property | Cartesian2;
  }) {
    this.setConstantProperty('material', new GridMaterialProperty(options))
    return this
  }

  setImageMaterial (options: {
    image?: Property | string | HTMLImageElement | HTMLCanvasElement | HTMLVideoElement;
    repeat?: Property | Cartesian2;
    color?: Property | Color;
    transparent?: Property | boolean;
  }) {
    this.setConstantProperty('material', new ImageMaterialProperty(options))
    return this
  }

  setPolylineGlowMaterial (options: {
    color?: Property | Color;
    glowPower?: Property | number;
    taperPower?: Property | number;
  }) {
    this.setConstantProperty('material', new PolylineGlowMaterialProperty(options))
    return this
  }

  setPolylineDashMaterial (options: {
    color?: Property | Color;
    gapColor?: Property | Color;
    dashLength?: Property | number;
    dashPattern?: Property | number;
  }) {
    this.setConstantProperty('material', new PolylineDashMaterialProperty(options))
    return this
  }

  setPolylineOutlineMaterial (options: {
    color?: Property | Color;
    outlineColor?: Property | Color;
    outlineWidth?: Property | number;
  }) {
    this.setConstantProperty('material', new PolylineOutlineMaterialProperty(options))

    return this
  }

  setStripeMaterial (options: {
    orientation?: Property | StripeOrientation;
    evenColor?: Property | Color;
    oddColor?: Property | Color;
    offset?: Property | number;
    repeat?: Property | number;
  }) {
    this.setConstantProperty('material', new StripeMaterialProperty(options))
    return this
  }
}
