import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return !$setup.inBackground ? (_openBlock(), _createBlock($setup["WebRtcView2"], {
    key: 0,
    ref: "webRtcRef",
    "class": "deep-rtc-player",
    "video-src": $setup.pullSrc,
    onVideoSize: $setup.handleSizeChange,
    autoPlay: ""
  }, null, 8 /* PROPS */, ["video-src"])) : _createCommentVNode("v-if", true);
}