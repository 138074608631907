import GraphicsBaseHelper from '@/components/cesium/entities/GraphicsBaseHelper'
import EntityHelper from '@/components/cesium/entities/EntityHelper'
import {
  BillboardGraphics,
  ConstantProperty,
  DistanceDisplayCondition,
  Color,
  Cartesian3,
  HeightReference, HorizontalOrigin,
  LabelGraphics, PinBuilder,
  Property, VerticalOrigin, BoundingRectangle, NearFarScalar, Cartesian2
} from 'cesium'

export default class BillboardHelper extends GraphicsBaseHelper {
  private pinBuilder:PinBuilder
  constructor (entityHelper: EntityHelper) {
    super(entityHelper)
    if (!this.entity.billboard) {
      this.entity.billboard = new BillboardGraphics()
    }
    this.pinBuilder = new PinBuilder()
  }

  private setConstantProperty<T extends keyof BillboardGraphics> (param: T, p: any) {
    if (!p) {
      return
    }

    if (this.entity.billboard![param]) {
      if (this.entity.billboard![param] instanceof ConstantProperty) {
        (this.entity.billboard![param] as ConstantProperty).setValue(p)
      } else {
          // @ts-ignore
          this.entity.billboard![param] = p
      }
    } else {
        // @ts-ignore
        this.entity.billboard![param] = p
    }
  }

  setDisableDepthTestDistance (near: number, far: number) {
    this.setConstantProperty('disableDepthTestDistance', new DistanceDisplayCondition(near, far))
    return this
  }

  setDistanceDisplayCondition (near: number, far: number) {
    this.setConstantProperty('distanceDisplayCondition', new DistanceDisplayCondition(near, far))
    return this
  }

  setImageSubRegion (imageSubRegion: BoundingRectangle|Property) {
    this.setConstantProperty('imageSubRegion', imageSubRegion)
    return this
  }

  setPixelOffsetScaleByDistance (pixelOffsetScaleByDistance: NearFarScalar|Property) {
    this.setConstantProperty('pixelOffsetScaleByDistance', pixelOffsetScaleByDistance)
    return this
  }

  setTranslucencyByDistance (translucencyByDistance: NearFarScalar|Property) {
    this.setConstantProperty('translucencyByDistance', translucencyByDistance)
    return this
  }

  setScaleByDistance (scaleByDistance: NearFarScalar|Property) {
    this.setConstantProperty('scaleByDistance', scaleByDistance)

    return this
  }

  setHeight (height: number|Property) {
    this.setConstantProperty('height', height)
    return this
  }

  setWidth (width: number|Property) {
    this.setConstantProperty('width', width)
    return this
  }

  setSizeInMeters (sizeInMeters: boolean|Property) {
    this.setConstantProperty('sizeInMeters', sizeInMeters)
    return this
  }

  setAlignedAxis (alignedAxis: Cartesian3|Property) {
    this.setConstantProperty('alignedAxis', alignedAxis)
    return this
  }

  setRotation (rotation: number|Property) {
    this.setConstantProperty('rotation', rotation)
    return this
  }

  setHeightReference (heightReference: HeightReference|Property) {
    this.setConstantProperty('heightReference', heightReference)
    return this
  }

  setVerticalOrigin (verticalOrigin: VerticalOrigin|Property) {
    this.setConstantProperty('verticalOrigin', verticalOrigin)
    return this
  }

  setHorizontalOrigin (horizontalOrigin: HorizontalOrigin|Property) {
    this.setConstantProperty('horizontalOrigin', horizontalOrigin)
    return this
  }

  setEyeOffset (eyeOffset: Cartesian3|Property) {
    this.setConstantProperty('eyeOffset', eyeOffset)
    return this
  }

  setPixelOffset (pixelOffset: Cartesian2|Property) {
    this.setConstantProperty('pixelOffset', pixelOffset)
    return this
  }

  setImage (image: string|Property) {
    this.setConstantProperty('image', image)
    return this
  }

  // 设置图钉
  setImagePin (text: string, color: Color, size: number) {
    this.setConstantProperty('image', this.pinBuilder.fromText(text, color, size))
    return this
  }

  setScale (scale: number|Property) {
    this.setConstantProperty('scale', scale)
    return this
  }

  setColor (color: Color|Property) {
    this.setConstantProperty('color', color)
    return this
  }
}
