import BaseMaker from '@/components/cesium/BaseMaker'
import CesiumInstance from '@/components/cesium/CesiumInstance'
import Cesium, { Clock, JulianDate } from 'cesium'

export default class CesiumClock extends BaseMaker {
  clock:Cesium.Clock
  constructor (viewer: Cesium.Viewer) {
    super()
    this.clock = viewer.clock
  }

  setMultiplier (multiplier:number) {
    this.clock.multiplier = multiplier
    return this
  }

  setShouldAnimate (shouldAnimate:boolean) {
    this.clock.shouldAnimate = shouldAnimate
    return this
  }

  setCurrentTime (currentTime:JulianDate|Date) {
    this.clock.currentTime = CesiumInstance.getJulianDate(currentTime)
    return this
  }

  addOnTickListener (callback: (clock: Clock) => void, context?: any) {
    const listener = this.getEventListenerSave('this.clock.onTick', this.clock.onTick)
    listener.register(callback, context || this)
    return this
  }

  removeOnTickListener (callback: (clock: Clock) => void) {
    const listener = this.getEventListenerSave('this.clock.onTick', this.clock.onTick)
    listener.unRegister(callback)
    return this
  }

  getCurrentTime () {
    return this.clock.currentTime
  }

  destroy () {
    super.destroy()
  }
}
