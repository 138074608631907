import "core-js/modules/es.array.reverse.js";
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createVNode as _createVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-40510a9d"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "deep-refresh-view",
  ref: "rootContainer"
};
var _hoisted_2 = {
  key: 1
};
var _hoisted_3 = {
  key: 1,
  "class": "pull-load"
};
var _hoisted_4 = {
  key: 2,
  "class": "scroll-body-placeholder"
};
var _hoisted_5 = {
  key: 0
};
var _hoisted_6 = ["data-index"];
var _hoisted_7 = {
  key: 1
};
var _hoisted_8 = {
  key: 1
};
var _hoisted_9 = {
  key: 5,
  "class": "pull-load"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    style: _normalizeStyle({
      height: $setup.rootClientHeight + 'px'
    }),
    ref: "scrollView",
    "class": "scroll",
    onTouchstart: $setup.touchstart,
    onTouchmove: $setup.touchmove,
    onTouchend: $setup.touchend,
    onScroll: $setup.onscroll
  }, [!$setup.reverse ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    "class": _normalizeClass(["pull-refresh", $setup.dragAnim ? 'pull-refresh-anim' : '']),
    style: _normalizeStyle({
      height: $setup.dragOffset + 'px'
    })
  }, [$setup.refreshing ? (_openBlock(), _createBlock($setup["DeepLoading"], {
    key: 0
  })) : (_openBlock(), _createElementBlock("div", _hoisted_2))], 6 /* CLASS, STYLE */)) : _createCommentVNode("v-if", true), $setup.reverse && $setup.loading ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode($setup["DeepLoading"])])) : _createCommentVNode("v-if", true), _renderSlot(_ctx.$slots, "header", {}, undefined, true), $setup.dataList.length === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_renderSlot(_ctx.$slots, "empty", {}, undefined, true)])) : (_openBlock(), _createElementBlock("div", {
    key: 3,
    style: _normalizeStyle([{
      "width": "100%",
      "height": "auto"
    }, {
      'flex-direction': $setup.reverse ? 'column-reverse' : 'column',
      'display': 'flex'
    }])
  }, [!$setup.reverse && $setup.loadMoreAble && $setup.nomore && $setup.dataList.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_renderSlot(_ctx.$slots, "nomore", {}, function () {
    return [_createCommentVNode("            <div class=\"nomore\">nomore</div>")];
  }, true)])) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dataList, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      key: $setup.key ? item[$setup.key] : index,
      onClick: $setup.itemClick,
      "data-index": index,
      style: {
        "width": "100%"
      }
    }, [_renderSlot(_ctx.$slots, "default", {
      data: item
    }, undefined, true)], 8 /* PROPS */, _hoisted_6);
  }), 128 /* KEYED_FRAGMENT */)), $setup.reverse && $setup.loadMoreAble && $setup.nomore && $setup.dataList.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_renderSlot(_ctx.$slots, "nomore", {}, function () {
    return [_createCommentVNode("            <div class=\"nomore\">nomore</div>")];
  }, true)])) : _createCommentVNode("v-if", true)], 4 /* STYLE */)), _renderSlot(_ctx.$slots, "footer", {}, undefined, true), $setup.reverse ? (_openBlock(), _createElementBlock("div", {
    key: 4,
    "class": _normalizeClass(["pull-refresh", $setup.dragAnim ? 'pull-refresh-anim' : '']),
    style: _normalizeStyle({
      height: $setup.dragOffset + 'px'
    })
  }, [$setup.refreshing ? (_openBlock(), _createBlock($setup["DeepLoading"], {
    key: 0
  })) : (_openBlock(), _createElementBlock("div", _hoisted_8))], 6 /* CLASS, STYLE */)) : _createCommentVNode("v-if", true), !$setup.reverse && $setup.loading ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode($setup["DeepLoading"])])) : _createCommentVNode("v-if", true)], 36 /* STYLE, NEED_HYDRATION */)], 512 /* NEED_PATCH */);
}