import { Events$1 } from '@/components/deep-webrtc-player/zlmediakit/Utils'

export class ZlEvent {
  private type:string
  private listener:StringKeyRecord<Function[]>

  constructor (type:string) {
    this.listener = {}
    this.type = type || ''
  }

  on (event:Events$1, fn:Function) {
    if (!this.listener[event]) {
      this.listener[event] = []
    }
    this.listener[event].push(fn)
    return true
  }

  off (event:Events$1, fn:Function) {
    if (this.listener[event]) {
      const index = this.listener[event].indexOf(fn)
      if (index > -1) {
        this.listener[event].splice(index, 1)
      }
      return true
    }
    return false
  }

  offAll () {
    this.listener = {}
  }

  dispatch (event:string, data:any) {
    if (this.listener[event]) {
      this.listener[event].forEach(each => {
        each(data)
      })
      return true
    }
    return false
  }
}
