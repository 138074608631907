import PolylineGraphicsHelper from '@/components/cesium/entities/PolylineGraphicsHelper'
import ModelGraphicsHelper from '@/components/cesium/entities/ModelGraphicsHelper'
import BillboardHelper from '@/components/cesium/entities/BillboardHelper'
import LabelGraphicsHelper from '@/components/cesium/entities/LabelGraphicsHelper'
import PolygonGraphicsHelper from '@/components/cesium/entities/PolygonGraphicsHelper'
import CylinderGraphicsHelper from '@/components/cesium/entities/CylinderGraphicsHelper'
import PointGraphicsHelper from '@/components/cesium/entities/PointGraphicsHelper'
import JlinkUtils from '@/common/global/JlinkUtils'
import RectangleGraphicsHelper from '@/components/cesium/entities/RectangleGraphicsHelper'
import { Cartesian3, ConstantPositionProperty, Entity } from 'cesium'
import CesiumInstance from '@/components/cesium/CesiumInstance'
import CesiumEntity from '@/components/cesium/CesiumEntity'

export default class EntityHelper {
  private uuid = JlinkUtils.random.uuid(8)
  em: CesiumEntity
  entity: Entity
  PointGraphicsHelper?:PointGraphicsHelper
  polylineGraphicsHelper?:PolylineGraphicsHelper
  polygonGraphicsHelper?:PolygonGraphicsHelper
  modelGraphicsHelper?:ModelGraphicsHelper
  billboardHelper?:BillboardHelper
  labelGraphicsHelper?:LabelGraphicsHelper
  cylinderGraphicsHelper?:CylinderGraphicsHelper
  rectangleGraphicsHelper?:RectangleGraphicsHelper
  constructor (em: CesiumEntity, entity?: Entity) {
    this.em = em
    if (entity) {
      this.entity = entity
    } else {
      this.entity = new Entity()
    }
    // @ts-ignore
    this.entity.helper = this
  }

  setName (name: string) {
    this.entity.name = name
    return this
  }

  setShow (show: boolean) {
    this.entity.show = show
    return this
  }

  drawCylinder () {
    if (!this.cylinderGraphicsHelper) {
      this.cylinderGraphicsHelper = new CylinderGraphicsHelper(this)
    }
    return this.cylinderGraphicsHelper
  }

  drawPoint () {
    if (!this.PointGraphicsHelper) {
      this.PointGraphicsHelper = new PointGraphicsHelper(this)
    }
    return this.PointGraphicsHelper
  }

  drawRectangle () {
    if (!this.rectangleGraphicsHelper) {
      this.rectangleGraphicsHelper = new RectangleGraphicsHelper(this)
    }
    return this.rectangleGraphicsHelper
  }

  drawPolyline () {
    if (!this.polylineGraphicsHelper) {
      this.polylineGraphicsHelper = new PolylineGraphicsHelper(this)
    }
    return this.polylineGraphicsHelper
  }

  drawPolygon () {
    if (!this.polygonGraphicsHelper) {
      this.polygonGraphicsHelper = new PolygonGraphicsHelper(this)
    }
    return this.polygonGraphicsHelper
  }

  drawModel () {
    if (!this.modelGraphicsHelper) {
      this.modelGraphicsHelper = new ModelGraphicsHelper(this)
    }
    return this.modelGraphicsHelper
  }

  drawLabel () {
    if (!this.labelGraphicsHelper) {
      this.labelGraphicsHelper = new LabelGraphicsHelper(this)
    }
    return this.labelGraphicsHelper
  }

  drawBillboard () {
    if (!this.billboardHelper) {
      this.billboardHelper = new BillboardHelper(this)
    }
    return this.billboardHelper
  }

  setPosition (position: JlinkLocation) {
    const cartesian3 = CesiumInstance.toCartesian3FromDegrees(position)
    this.setPositionByCartesian3(cartesian3)
    return this
  }

  setPositionByCartesian3 (cartesian3: Cartesian3) {
    this.entity.position = new ConstantPositionProperty(cartesian3)
    return this
  }

  setOrientation (position:JlinkLocation, heading?: number, pitch?:number, roll?:number) {
    const o = CesiumInstance.getOrientation({ ...position, heading, pitch, roll })
    if (this.entity.orientation instanceof ConstantPositionProperty) {
      this.entity.orientation.setValue(o)
    } else {
      // @ts-ignore
      this.entity.orientation = o
    }
    return this
  }

  addToView () {
    const exist = this.em.getById(this.entity.id)
    if (exist) {
      console.error(`entity ${this.entity.id} have added`, this.entity)
      return this
    }
    this.em.add(this.entity)
    const _this = this
    // nextTick(function () {
    //   _this.bmgl.getScene().requestRender()
    // })
    return this
  }

  coverToView () {
    this.em.removeById(this.entity.id)
    this.em.add(this.entity)
    return this
  }
}
