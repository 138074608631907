import * as cesium from 'cesium'

type EventsListener = (...args: any[]) => void
export default abstract class BaseMaker {
  private eventListenerSave: StringKeyRecord<{
        register: (callback: EventsListener, context: any) => void,
        unRegister: (callback: EventsListener) => void,
        destroy: () => void
    }> = {}

  private autoMakeEventListenSave (event: cesium.Event) {
    const listenerSave: Set<EventsListener> = new Set()
    let listener: EventsListener | undefined
    function register (callback: EventsListener, context: any) {
      if (!listener) {
        listener = function () {
          const args = arguments
          listenerSave.forEach(i => {
            // @ts-ignore
            i.apply(context, [...args])
          })
        }
        event.addEventListener(listener)
      }
      listenerSave.add(callback)
    }

    function unRegister (callback: EventsListener) {
      listenerSave.delete(callback)
    }

    function destroy () {
      listenerSave.clear()
      listener && event.removeEventListener(listener)
    }

    return { register, unRegister, destroy }
  }

  protected getEventListenerSave (name: string, event: cesium.Event) {
    let listener = this.eventListenerSave[name]
    if (!listener) {
      this.eventListenerSave[name] = this.autoMakeEventListenSave(event)
      listener = this.eventListenerSave[name]
    }
    return listener
  }

  destroy () {
    Object.keys(this.eventListenerSave).forEach(i => {
      this.eventListenerSave[i]?.destroy()
    })
    this.eventListenerSave = {}
  }
}
