import GraphicsBaseHelper from '@/components/cesium/entities/GraphicsBaseHelper'
import EntityHelper from '@/components/cesium/entities/EntityHelper'
import Cesium, {
  ConstantProperty,
  ModelGraphics,
  Property,
  Color,
  Cartesian3,
  Cartesian2,
  DistanceDisplayCondition, Resource, CallbackProperty
} from 'cesium'

export default class ModelGraphicsHelper extends GraphicsBaseHelper {
  private scaleSave: number = 1
  private modelUri:string|undefined

  constructor (entityHelper: EntityHelper) {
    super(entityHelper)
    if (!this.entity.model) {
      this.entity.model = new ModelGraphics()
    }
    // const _this=this
    // this.entity.model.uri=new CallbackProperty(function () {
    //   return _this.modelUri||''
    //
    // },false)
  }

  private setConstantProperty<T extends keyof ModelGraphics> (param: T, p: any) {
    if (!p) {
      return
    }
    if (this.entity.model![param]) {
      if (this.entity.model![param] instanceof ConstantProperty) {
        (this.entity.model![param] as ConstantProperty).setValue(p)
      } else {
        // @ts-ignore
        this.entity.model![param] = p
      }
    } else {
      // @ts-ignore
      this.entity.model![param] = p
    }
  }

  // default true	指定模型可见性的布尔属性。

  setShow (show: boolean | Property) {
    this.setConstantProperty('show', show)
    return this
  }

  setUri (uri: string) {
    this.modelUri = uri
    this.setConstantProperty('uri', uri)
    return this
  }

  setScale (scale: number) {
    this.scaleSave = scale
    this.setConstantProperty('scale', scale)
    return this
  }

  // 根据地图层级自动缩放
  setLevelScale (level: number) {
    let offset = 1
    if (level > 15) {
      offset = offset - (level - 15) / 7
    }
    console.log('tileslevel', this.scaleSave * offset)
    this.setConstantProperty('scale', Math.max(this.scaleSave * offset, 0.1))
    return this
  }

  getScale () {
    return this.entity.model!.scale
  }

  setMinimumPixelSize (minimumPixelSize: number | Property) {
    this.setConstantProperty('minimumPixelSize', minimumPixelSize)
    return this
  }

  setMaximumScale (maximumScale: number | Property) {
    this.setConstantProperty('maximumScale', maximumScale)
    return this
  }

  setIncrementallyLoadTextures (incrementallyLoadTextures: boolean | Property) {
    this.setConstantProperty('incrementallyLoadTextures', incrementallyLoadTextures)
    return this
  }

  setRunAnimations (runAnimations: boolean | Property) {
    this.setConstantProperty('runAnimations', runAnimations)
    return this
  }

  setClampAnimations (clampAnimations: boolean | Property) {
    this.setConstantProperty('clampAnimations', clampAnimations)
    return this
  }

  setShadows (shadows: Cesium.ShadowMode | Property) {
    this.setConstantProperty('shadows', shadows)
    return this
  }

  setHeightReference (heightReference: Cesium.HeightReference | Property) {
    this.setConstantProperty('heightReference', heightReference)
    return this
  }

  setSilhouetteColor (silhouetteColor?: Color | Property) {
    this.setConstantProperty('silhouetteColor', silhouetteColor)
    return this
  }

  setSilhouetteSize (silhouetteSize: number | Property) {
    this.setConstantProperty('silhouetteSize', silhouetteSize)
    return this
  }

  setColor (color?: Color | Property) {
    this.setConstantProperty('silhouetteSize', color)
    return this
  }

  setColorBlendMode (colorBlendMode: Cesium.ColorBlendMode | Property) {
    this.setConstantProperty('colorBlendMode', colorBlendMode)
    return this
  }

  setColorBlendAmount (colorBlendAmount: number | Property) {
    this.setConstantProperty('colorBlendAmount', colorBlendAmount)
    return this
  }

  setImageBasedLightingFactor (c: Cartesian2 | Property) {
    this.setConstantProperty('imageBasedLightingFactor', c)
    return this
  }

  setImageBasedLightingFactor2 (factor: { x: number, y: number }) {
    this.setImageBasedLightingFactor(new Cartesian2(factor.x, factor.y))
    return this
  }

  setLightColor (color?: Color | Property) {
    this.setConstantProperty('lightColor', color)
    return this
  }

  setDistanceDisplayCondition (near: number, far: number) {
    this.setConstantProperty('distanceDisplayCondition', new DistanceDisplayCondition(near, far))
    return this
  }

  //   nodeTransformations:any
  //   //  一种对象，其中键由一个连接名、一个空格和一个阶段名组成，值是数字属性。
  //   articulations:any
  //   //  指定ClippingPlaneCollection的属性，用于选择性地禁用呈现模型。
  //   clippingPlanes:ClippingPlaneCollection
}
