import GraphicsBaseHelper from '@/components/cesium/entities/GraphicsBaseHelper'
import EntityHelper from '@/components/cesium/entities/EntityHelper'
import {
  CallbackProperty,
  ConstantProperty,
  HeightReference,
  Color,
  PolygonGraphics,
  PolygonHierarchy,
  Property,
  ArcType,
  ShadowMode,
  DistanceDisplayCondition,
  PolylineArrowMaterialProperty,
  ColorMaterialProperty,
  CompositeMaterialProperty,
  GridMaterialProperty,
  Cartesian2,
  ImageMaterialProperty,
  PolylineGlowMaterialProperty,
  PolylineDashMaterialProperty,
  PolylineOutlineMaterialProperty,
  StripeMaterialProperty,
  StripeOrientation,
  ClassificationType
} from 'cesium'
import CesiumInstance from '@/components/cesium/CesiumInstance'

export default class PolygonGraphicsHelper extends GraphicsBaseHelper {
  constructor (entityHelper: EntityHelper) {
    super(entityHelper)
    const _this = this
    if (!this.entity.polygon) {
      this.entity.polygon = new PolygonGraphics()
      this.entity.polygon.hierarchy = new CallbackProperty(function () {
        return new PolygonHierarchy(_this.positionSave.map(i => CesiumInstance.toCartesian3FromDegrees(i)))
      }, false)
    }
  }

  private setConstantProperty<T extends keyof PolygonGraphics> (param: T, p: any) {
    if (!p) {
      return
    }
    if (this.entity.polygon![param]) {
      if (this.entity.polygon![param] instanceof ConstantProperty) {
        (this.entity.polygon![param] as ConstantProperty).setValue(p)
      } else {
        this.entity.polygon![param] = p
      }
    } else {
      this.entity.polygon![param] = p
    }
  }

  setHeightReference (heightReference: HeightReference) {
    this.setConstantProperty('heightReference', heightReference)
    return this
  }

  setExtrudedHeight (extrudedHeight: number) {
    this.setConstantProperty('extrudedHeight', extrudedHeight)
    return this
  }

  setExtrudedHeightReference (extrudedHeightReference: HeightReference) {
    this.setConstantProperty('extrudedHeightReference', extrudedHeightReference)
    return this
  }

  setStRotation (stRotation: number) {
    this.setConstantProperty('stRotation', stRotation)
    return this
  }

  setFill (fill: boolean) {
    this.setConstantProperty('fill', fill)
    return this
  }

  setOutline (outline: boolean) {
    this.setConstantProperty('outline', outline)
    return this
  }

  setOutlineColor (outlineColor: Color) {
    this.setConstantProperty('outlineColor', outlineColor)
    return this
  }

  setOutlineWidth (outlineWidth: number) {
    this.setConstantProperty('outlineWidth', outlineWidth)
    return this
  }

  setPerPositionHeight (perPositionHeight: boolean) {
    this.setConstantProperty('perPositionHeight', perPositionHeight)
    return this
  }

  setCloseTop (closeTop: boolean) {
    this.setConstantProperty('closeTop', closeTop)
    return this
  }

  setCloseBottom (closeBottom: boolean) {
    this.setConstantProperty('closeBottom', closeBottom)

    return this
  }

  setShow (show: boolean) {
    this.setConstantProperty('show', show)
    return this
  }

  positionSave: JlinkLocation[] = []

  setHierarchy (positions: JlinkLocation[]) {
    this.positionSave = positions
    return this
  }

  setHeight (height: number) {
    this.setConstantProperty('height', height)
    return this
  }

  setArcType (arcType: ArcType) {
    this.setConstantProperty('arcType', arcType)
    return this
  }

  setGranularity (granularity: number) {
    this.setConstantProperty('granularity', granularity)
    return this
  }

  setZIndex (zIndex: number) {
    this.setConstantProperty('zIndex', zIndex)
    return this
  }

  setShadows (shadows: ShadowMode) {
    this.setConstantProperty('shadows', shadows)
    return this
  }

  setDistanceDisplayCondition (near: number, far: number) {
    this.setConstantProperty('distanceDisplayCondition', new DistanceDisplayCondition(near, far))
    return this
  }

  /* material设置方法除了colorMaterial其他待验证 */

  setPolylineArrowMaterial (color: Color) {
    this.setConstantProperty('material', new PolylineArrowMaterialProperty(color))
    return this
  }

  setColorMaterial (color: Color) {
    this.setConstantProperty('material', new ColorMaterialProperty(color))
    return this
  }

  setCompositeMaterial () {
    this.setConstantProperty('material', new CompositeMaterialProperty())

    return this
  }

  setGridMaterial (options: {
    color?: Property | Color;
    cellAlpha?: Property | number;
    lineCount?: Property | Cartesian2;
    lineThickness?: Property | Cartesian2;
    lineOffset?: Property | Cartesian2;
  }) {
    this.setConstantProperty('material', new GridMaterialProperty(options))
    return this
  }

  setImageMaterial (options: {
    image?: Property | string | HTMLImageElement | HTMLCanvasElement | HTMLVideoElement;
    repeat?: Property | Cartesian2;
    color?: Property | Color;
    transparent?: Property | boolean;
  }) {
    this.setConstantProperty('material', new ImageMaterialProperty(options))

    return this
  }

  setPolylineGlowMaterial (options: {
    color?: Property | Color;
    glowPower?: Property | number;
    taperPower?: Property | number;
  }) {
    this.setConstantProperty('material', new PolylineGlowMaterialProperty(options))
    return this
  }

  setPolylineDashMaterial (options: {
    color?: Property | Color;
    gapColor?: Property | Color;
    dashLength?: Property | number;
    dashPattern?: Property | number;
  }) {
    this.setConstantProperty('material', new PolylineDashMaterialProperty(options))
    return this
  }

  setPolylineOutlineMaterial (options?: {
    color?: Property | Color;
    outlineColor?: Property | Color;
    outlineWidth?: Property | number;
  }) {
    this.setConstantProperty('material', new PolylineOutlineMaterialProperty(options))
    return this
  }

  setStripeMaterial (options: {
    orientation?: Property | StripeOrientation;
    evenColor?: Property | Color;
    oddColor?: Property | Color;
    offset?: Property | number;
    repeat?: Property | number;
  }) {
    this.setConstantProperty('material', new StripeMaterialProperty(options))
    return this
  }

  setClassificationType (classificationType: ClassificationType) {
    this.setConstantProperty('classificationType', classificationType)
    return this
  }
}
