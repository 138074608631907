export default class SrsRtcBase {
  protected pc = new RTCPeerConnection()
  stream = new MediaStream()

  srsRtcFormatSenders (senders: RTCRtpSender[], kind: 'audio' | 'video') {
    const codecs: string[] = []
    senders.forEach(function (sender) {
      const params = sender.getParameters()
      params && params.codecs && params.codecs.forEach(function (c) {
        if (kind && sender.track?.kind !== kind) {
          return
        }

        if (c.mimeType.indexOf('/red') > 0 || c.mimeType.indexOf('/rtx') > 0 || c.mimeType.indexOf('/fec') > 0) {
          return
        }
        let s = ''
        s += c.mimeType.replace('audio/', '').replace('video/', '')
        s += ', ' + c.clockRate + 'HZ'
        if (sender.track?.kind === 'audio') {
          s += ', channels: ' + c.channels
        }
        s += ', pt: ' + c.payloadType

        codecs.push(s)
      })
    })
    return codecs.join(', ')
  }

  close () {
    this.pc.close()
  }

  // The callback when got local stream.
  // @see https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/addStream#Migrating_to_addTrack
  protected ontrack (event: { track: MediaStreamTrack }) {
    // Add track to stream of SDK.
    this.stream.addTrack(event.track)
  };
}
