import {
  Cartesian3,
  JulianDate,
  Rectangle,
  Ellipsoid,
  BoundingSphere,
  Matrix4,
  Cartographic,
  EllipsoidGeodesic,
  Color,
  Math as Math2,
  Transforms,
  HeadingPitchRoll
} from 'cesium'
import CesiumException from '@/components/cesium/CesiumException'

export default class CesiumInstance {
  // 正则不是十分完善
  private colorRegex = /^#([a-fA-F\d]{3}|[a-fA-F\d]{6})|^rgb|^rgba|^hsla/

  static toRectangleFromCartesianArray = (cartesian: Cartesian3[]) => Rectangle.fromCartesianArray(cartesian)

  static toCartesian3ArrayFromRectangleSubSample = (rectangle: Rectangle, ellipsoid?: Ellipsoid, surfaceHeight?: number, result?: Cartesian3[]) => Rectangle.subsample(rectangle, ellipsoid, surfaceHeight, result)

  static toCartesian3FromCartesian3Subtract (left: Cartesian3, right: Cartesian3, result: Cartesian3) {
    return Cartesian3.subtract(left, right, result)
  }

  static toBoundingSphereFromCartesianArray (points: Cartesian3[], result?: BoundingSphere) {
    return BoundingSphere.fromPoints(points, result)
  }

  static toMatrix4FromCartesian3 (translation: Cartesian3, result?: Matrix4) {
    return Matrix4.fromTranslation(translation, result)
  }

  static toCartesian3FromDegrees (location: JlinkLocation, ellipsoid?:Ellipsoid, result?: Cartesian3) {
    if (location.lng === null || location.lng === undefined) {
      throw new CesiumException('location lng is lost')
    }
    if (location.lat === null || location.lat === undefined) {
      throw new CesiumException('location lat is lost')
    }
    if (location.height === null || location.height === undefined) {
      throw new CesiumException('location height is lost')
    }
    return Cartesian3.fromDegrees(location.lng, location.lat, location.height, ellipsoid, result)
  }

  static toCartographicFromCartesian3 (cartesian: Cartesian3, ellipsoid?: Ellipsoid, result?: Cartographic) {
    return Cartographic.fromCartesian(cartesian, ellipsoid, result)
  }

  static toBigemapLocationFromCartesian3 (p?: Cartesian3): JlinkLocation {
    if (p) {
      // 将笛卡尔坐标转换为地理坐标
      const cartographic = this.toCartographicFromCartesian3(p)
      // 将弧度转为度的十进制度表示
      return this.toBigemapLocationFromCartographic(cartographic)
    } else {
      return { lng: 0, lat: 0, height: 0 }
    }
  }

  static toBigemapLocationFromCartographic (c: Cartographic): JlinkLocation {
    // 将笛卡尔坐标转换为地理坐标
    // 将弧度转为度的十进制度表示
    const longitudeString = this.toDegreesFromRadians(c.longitude)
    const latitudeString = this.toDegreesFromRadians(c.latitude)
    // constant heightString = this.radiansToDegrees(cartographic.height)
    // console.log(this.sceneMaker?.globeHeight(cartographic))
    return { lng: longitudeString.toFixedCustom(9), lat: latitudeString.toFixedCustom(9), height: c.height }
  }

  static toDegreesFromRadians (radians: number) {
    return Math2.toDegrees(radians)
  }

  static toRadiansFromDegrees (degrees: number) {
    return Math2.toRadians(degrees)
  }

  static toCartesian3ArrayFromHeightArray (coordinates: number[], ellipsoid?: Ellipsoid, result?: Cartesian3[]) {
    return Cartesian3.fromDegreesArrayHeights(coordinates, ellipsoid, result)
  }

  static computeDistanceBetweenCartographic (start: JlinkLocation, end: JlinkLocation) {
    const endCartographic = this.toCartesian3FromDegrees(end)
    const startCartographic = this.toCartesian3FromDegrees(start)
    return Cartesian3.distance(startCartographic, endCartographic)
  }

  static computeDistanceOnload (start: JlinkLocation, end: JlinkLocation) {
    const endCartographic = this.toCartographicFromCartesian3(this.toCartesian3FromDegrees(end))
    const startCartographic = this.toCartographicFromCartesian3(this.toCartesian3FromDegrees(start))
    const geodesic = new EllipsoidGeodesic()
    geodesic.setEndPoints(startCartographic, endCartographic)
    return Math.round(geodesic.surfaceDistance)
  }

  static getMidpointOnLoad (start: JlinkLocation, end: JlinkLocation) {
    const endCartographic = this.toCartographicFromCartesian3(this.toCartesian3FromDegrees(end))
    const startCartographic = this.toCartographicFromCartesian3(this.toCartesian3FromDegrees(start))
    const geodesic = new EllipsoidGeodesic()
    geodesic.setEndPoints(startCartographic, endCartographic)
    return geodesic.interpolateUsingFraction(0.5)
  }

  static getMidpointBetweenCartographic (start: JlinkLocation, end: JlinkLocation) {
    const endCartographic = this.toCartesian3FromDegrees(end)
    const startCartographic = this.toCartesian3FromDegrees(start)
    const middleCartesian3 = Cartesian3.midpoint(startCartographic, endCartographic, new Cartesian3())
    return this.toCartographicFromCartesian3(middleCartesian3, undefined, new Cartographic())
  }

  static distanceToBoundingSphere3D (frustum: any, radius: number) {
    const tanPhi = Math.tan(frustum.fovy * 0.5)
    const tanTheta = frustum.aspectRatio * tanPhi
    return Math.max(radius / tanTheta, radius / tanPhi)
  }

  static distanceToBoundingSphere2D (frustum: any, radius: number) {
    const offCenterFrustum = frustum.offCenterFrustum
    if (offCenterFrustum) {
      frustum = offCenterFrustum
    }
    let right, top
    const ratio = frustum.right / frustum.top
    const heightRatio = radius * ratio
    if (radius > heightRatio) {
      right = radius
      top = right / ratio
    } else {
      top = radius
      right = heightRatio
    }

    return Math.max(right, top) * 1.5
  }

  getRectangleHeight (rectangle: Rectangle) {
    return Rectangle.computeHeight(rectangle)
  }

  static getJulianDate (date?: JulianDate | Date) {
    if (!date) {
      return JulianDate.now()
    }
    if (date instanceof Date) {
      return JulianDate.fromDate(date)
    } else {
      return date
    }
  }

  static getOrientation (data: JlinkLocation & { heading?: number, pitch?: number, roll?: number }) {
    return Transforms.headingPitchRollQuaternion(this.toCartesian3FromDegrees(data), new HeadingPitchRoll(this.toRadiansFromDegrees(data.heading || 0), this.toRadiansFromDegrees(data.pitch || 0), this.toRadiansFromDegrees(data.roll || 0)))
  }
}
