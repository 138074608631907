import GraphicsBaseHelper from '@/components/cesium/entities/GraphicsBaseHelper'
import EntityHelper from '@/components/cesium/entities/EntityHelper'
import {
  ConstantProperty,
  HeightReference,
  Color,
  Property,
  RectangleGraphics,
  ShadowMode,
  ColorMaterialProperty, DistanceDisplayCondition, Rectangle
} from 'cesium'

export default class RectangleGraphicsHelper extends GraphicsBaseHelper {
  constructor (entityHelper: EntityHelper) {
    super(entityHelper)
    if (!this.entity.rectangle) {
      this.entity.rectangle = new RectangleGraphics()
    }
  }

  private setConstantProperty<T extends keyof RectangleGraphics> (param: T, p: any) {
    if (!p) {
      return
    }
    if (this.entity.rectangle![param]) {
      if (this.entity.rectangle![param] instanceof ConstantProperty) {
        (this.entity.rectangle![param] as ConstantProperty).setValue(p)
      } else {
        this.entity.rectangle![param] = p
      }
    } else {
      this.entity.rectangle![param] = p
    }
  }

  setRotation (rotation: number) {
    this.setConstantProperty('rotation', rotation)
    return this
  }

  setStRotation (stRotation: number) {
    this.setConstantProperty('stRotation', stRotation)
    return this
  }

  setGranularity (granularity: number) {
    this.setConstantProperty('granularity', granularity)

    return this
  }

  setShadows (shadows: ShadowMode) {
    this.setConstantProperty('shadows', shadows)
    return this
  }

  setZIndex (zIndex: number) {
    this.setConstantProperty('zIndex', zIndex)
    return this
  }

  setRectangle (coordinates: Rectangle) {
    this.setConstantProperty('coordinates', coordinates)
    return this
  }

  setHeightReference (heightReference: HeightReference) {
    this.setConstantProperty('heightReference', heightReference)
    return this
  }

  setExtrudedHeightReference (extrudedHeightReference: HeightReference) {
    this.setConstantProperty('extrudedHeightReference', extrudedHeightReference)
    return this
  }

  setFill (fill: boolean) {
    this.setConstantProperty('fill', fill)
    return this
  }

  setOutlineColor (color: Color) {
    this.setConstantProperty('outlineColor', color)
    return this
  }

  setOutline (outline: boolean) {
    this.setConstantProperty('outline', outline)
    return this
  }

  setOutlineWidth (width: number) {
    this.setConstantProperty('outlineWidth', width)
    return this
  }

  setColorMaterial (color: Color) {
    this.setConstantProperty('material', new ColorMaterialProperty(color))
    return this
  }

  setHeight (height: number) {
    this.setConstantProperty('height', height)

    return this
  }

  setExtrudedHeight (extrudedHeight: number) {
    this.setConstantProperty('extrudedHeight', extrudedHeight)
    return this
  }

  setShow (show: boolean) {
    this.setConstantProperty('show', show)
    return this
  }

  setDistanceDisplayCondition (near: number, far: number) {
    this.setConstantProperty('distanceDisplayCondition', new DistanceDisplayCondition(near, far))
    return this
  }
}
